import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

export const SystemNotificationService = {
  getAllByUser: async (userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/SystemNotification/GetAllByUserId?userId=${userId}`,
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  setReaded: async (notificationId: number) => {
    try {
      const body = [
        {
          op: "replace",
          value: true,
          path: "read",
        },
      ];

      const { data } = await api.patch(
        `/SystemNotification/update?id=${notificationId}`,
        body,
      );
      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
