import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CreateEditAttendantRole } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";

//Toast

import { useToast } from "../../../hooks/toast";
import { AttendantRoleService } from "../../../services/attendantRole";

//Permissões

import { listSearch } from "../../../services/utils";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { stringFormat } from "../../../utils/stringFormat";

export interface AttendantRoleInterface {
  id: number;
  customerId: number;
  active: boolean;
  name: string;
}

type ISituation = true | false | undefined;

export function AttendantRole() {
  const [listAttendantRole, setListAttendantRole] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameAttendantRole, setNameAttendantRole] = useState<string>("");
  const [element, setElement] = useState<AttendantRoleInterface>();
  const [active, setActive] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");

  const user = getValueFromlocalStorage("@Kenta:user");

  const [fieldError, setFieldError] = useState<boolean>(false);
  const [startFocus, setStartFocus] = useState<boolean>(true);
  const [focusInputSearch, setFocusInputSearch] = useState<boolean>(true);
  const [situation, setSituation] = useState<ISituation>(true);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    const response = await AttendantRoleService.getAll(
      customerId,
      listSearch(situation),
    );
    if (response) {
      if (response.status) {
        setListAttendantRole(response.data?.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    dispatch(hideLoading());
    return [];
  }

  function clear() {
    setIsEdit(false);
    setNameAttendantRole("");
    setActive(true);
    setElement(undefined);
    setOpenModal(false);
    setFieldError(false);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  async function onClickAttendant(item: AttendantRoleInterface) {
    setIsEdit(true);
    setNameAttendantRole(item.name);
    setActive(item.active);
    setElement(item);
    setOpenModal(true);
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      active: active,
      name: nameAttendantRole,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  async function postAttendantRole() {
    if (!nameAttendantRole || stringFormat.isEmptyOrSpaces(nameAttendantRole)) {
      addToast({
        type: "warning",
        title: "Tipo de participante",
        description:
          "Para criar um tipo de participante é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      setStartFocus(true);
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listAttendantRole,
      nameAttendantRole,
      element?.id,
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      setStartFocus(true);
      return;
    }

    dispatch(showLoading());

    const resultPost = await AttendantRoleService.post("", createBody());
    if (resultPost.status === true) {
      addToast({
        type: "success",
        title: "Tipo de participante cadastrado",
        description: "Tipo de participante foi cadastrado com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      if (resultPost.status === false && resultPost.severity) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPost.message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Falha ao criar tipo de participante",
        });
      }
    }

    dispatch(hideLoading());
  }

  async function putAttendantRole() {
    if (!nameAttendantRole || stringFormat.isEmptyOrSpaces(nameAttendantRole)) {
      addToast({
        type: "warning",
        title: "Tipo de participante",
        description:
          "Para atualizar um tipo de participante é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      setStartFocus(true);
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listAttendantRole,
      nameAttendantRole,
      element?.id,
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      setStartFocus(true);
      return;
    }

    dispatch(showLoading());

    const resultPut = await AttendantRoleService.put("", createBody());
    if (resultPut.status === true) {
      addToast({
        type: "success",
        title: "Tipo de participante atualizado",
        description: "Tipo de participante foi atualizado com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      if (!resultPut.status) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPut.message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPut.message,
        });
      }
    }
    dispatch(hideLoading());
  }

  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };
  const filteredData = listAttendantRole.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );
  const situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation,
  );

  function onClickAplicar() {
    setStartFocus(false);
    if (isEdit) {
      putAttendantRole();
    } else {
      postAttendantRole();
    }
  }

  return (
    <>
      <AdminArea
        title={"Tipos de participantes"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        searchText={search}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={(item: any) => onClickAttendant(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        permissionName={FORMS_ADMIN.ATTENDANT_ROLE}
        focusInputSearch={focusInputSearch}
        onChangeSituation={(value: any) => handleSitualtion(value)}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={
          isEdit
            ? "Editando tipo de participante"
            : "Criar tipo de participante"
        }
        onClickAplicar={onClickAplicar}
        showIconDelete={false}
      >
        <CreateEditAttendantRole
          onChangeName={setNameAttendantRole}
          valueName={nameAttendantRole}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          fieldError={fieldError}
          startFocus={startFocus}
        />
      </ModalRight>
    </>
  );
}
