import React, { useEffect, useState } from "react";

import { FiEye, FiEyeOff } from "react-icons/fi";

import {
  Container,
  ContainerIcon,
  ContainerInput,
  InputText,
  Label,
} from "./styles";
import { CircleBadge } from "../Badges/Circle";

type IInput = {
  id?: string;
  width?: number;
  height?: number;
  marginLeft?: number;
  label: string;
  value?: string | number;
  icon?: boolean;
  maxLength?: number;
  type?: "text" | "number" | "date" | "time";
  error?: boolean;
  setType?: (e: any) => unknown;
  onChange: (e: string) => unknown;
  disabled?: boolean;
  required: boolean;
  limitToday?: boolean;
  deadlineDate?: string;
};

export const InputDateGlobal: React.FC<IInput> = ({
  id,
  label,
  maxLength,
  onChange,
  value,
  disabled = false,
  error = false,
  type = "text",
  setType,
  icon = false,
  width,
  height,
  required,
  marginLeft,
  limitToday = false,
  deadlineDate = "2099-12-31",
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [errorRequired, setErrorRequired] = useState<boolean>(false);
  const [deadlineDateState, setDeadlineDateState] =
    useState<string>(deadlineDate);

  const activeFocus = () => {
    setFocus(true);
  };

  const inactiveFocus = () => {
    setFocus(false);
  };

  function selectValue(e: any) {
    if (e !== null) {
      onChange(e);
      setErrorRequired(false);

      if (type === "date" && deadlineDate !== "2099-12-31") {
        getTodayWithDeadline(deadlineDate);
      }
    }
  }

  useEffect(() => {
    if (error) {
      setErrorRequired(true);
    }
  }, [error]);

  useEffect(() => {
    if (type === "date" && deadlineDate !== "2099-12-31") {
      getTodayWithDeadline(deadlineDate);
      setErrorRequired(false);
    }
  }, [deadlineDate]);

  function getToday() {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    return formatDays(day, month, year);
  }

  function formatDays(day: number, month: number, year: number) {
    return year + "-" + (month < 10 ? "0" + month : month) + "-" + day;
  }

  function getTodayWithDeadline(deadlineDate: string) {
    const customerProcedureDeadline = localStorage.getItem(
      "CustomerProcedureDeadline",
    );

    if (customerProcedureDeadline && customerProcedureDeadline !== "0") {
      if (deadlineDate) {
        const format = modifyDate(
          deadlineDate,
          parseInt(customerProcedureDeadline),
        );
        setDeadlineDateState(format);
        onChange(format);
      }
    } else {
      setDeadlineDateState("2099-12-31");
    }
  }

  function modifyDate(input: string, dayModification: number): string {
    // regex that will match ####-##-## where # is a number
    const isDateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;

    // javascript will natively understand both formats when parsing a string to Date
    const date = new Date(input);
    // add the dayModification value as days to the date
    date.setDate(date.getDate() + dayModification);

    // check if it's a dateOnly string
    if (isDateOnlyRegex.test(input)) {
      // using string format to return yyyy-MM-dd format
      const month = date.getMonth() + 1;
      const day = date.getDate();
      //return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
      return `${date.getFullYear()}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    }

    // date.toJSON returns yyyy-MM-ddTHH:mm:ss.SSSZ
    // could also use date.toISOString
    return date.toJSON();
  }

  return (
    <Container style={{ marginLeft: marginLeft }}>
      <Label>{label}</Label>
      {required && !disabled && <CircleBadge radius="50px" widthHeigt="8px" />}
      <ContainerInput
        width={width}
        height={height}
        notError={!errorRequired && !!value}
        error={errorRequired}
        focus={focus}
      >
        <InputText
          id={id}
          maxLength={maxLength}
          autoComplete="on"
          onFocus={activeFocus}
          onBlur={inactiveFocus}
          autoCapitalize="off"
          disabled={disabled}
          type={type}
          defaultValue={value}
          required={required}
          onChange={(e) => selectValue(e.target.value)}
          //min={type === "date" && limitToday === true ? getToday() : ""}
          min={
            type === "date" && deadlineDateState !== "2099-12-31"
              ? deadlineDate
              : type === "date" && limitToday === true
                ? getToday()
                : ""
          }
          max={deadlineDateState}
        />
        {icon && setType && (
          <ContainerIcon
            onClick={() => setType(type === "text" ? "password" : "text")}
            type="button"
          >
            {type === "text" ? (
              <FiEyeOff color="#7887A9" size={20} />
            ) : (
              <FiEye color="#7887A9" size={20} />
            )}
          </ContainerIcon>
        )}
      </ContainerInput>
    </Container>
  );
};
