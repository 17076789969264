import api from "../api";

export const lifeSignalService = {
  put: async (hash: any) => {
    try {
      const { data } = await api.put(
        `/UserCustomerAccess/RefreshAccess?hash=${hash}`,
      );
      return {
        success: true,
        data: data.data,
      };
    } catch (error: any) {
      return {
        success: false,
        data: error?.message,
      };
    }
  },
};
