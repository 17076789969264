import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { CreateEditProcedureCustomerType } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";
import { ProcedureCustomerTypeService } from "../../../services/procedureCustomerType";
import { listSearch } from "../../../services/utils";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { stringFormat } from "../../../utils/stringFormat";

//Toast

//Permissões

type ISituation = true | false | undefined;

interface ProcedureCustomerTypeInterface {
  id: number;
  foreignKey: string;
  name: string;
  customerId: number;
  active: boolean;
}

export const ProcedureCustomerType = () => {
  const [element, setElement] = useState<ProcedureCustomerTypeInterface>();
  const [name, setName] = useState<string>("");
  const [active, setActive] = useState(true);
  const [foreignKey, setForeignKey] = useState<string>("");
  const [listProcedureCustomerType, setListProcedureCustomerType] =
    useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [startFocus, setStartFocus] = useState<boolean>(true);

  const customerId: any = LogOperation.getCustomerId();
  const [situation, setSituation] = useState<ISituation>(true);

  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    const response = await ProcedureCustomerTypeService.getAll(
      customerId,
      listSearch(situation),
    );
    if (response) {
      if (response.status) {
        setListProcedureCustomerType(response.data?.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    dispatch(hideLoading());
    return [];
  }

  const filteredData = listProcedureCustomerType.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );
  const situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation,
  );

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  async function clear() {
    setIsEdit(false);
    setName("");
    setForeignKey("");
    setActive(true);
    setElement(undefined);
    setOpenModal(false);
    setFieldError(false);
  }

  async function onClick(item: ProcedureCustomerTypeInterface) {
    setIsEdit(true);
    setName(item.name);
    setActive(item.active);
    setForeignKey(item.foreignKey);
    setElement(item);
    setOpenModal(true);
  }

  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };

  function createBody() {
    return {
      //foreignKey: null,
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      active: active,
      name: name,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  function onClickAplicar() {
    setStartFocus(false);
    if (isEdit) {
      putAttendantRole();
    } else {
      postAttendantRole();
    }
  }

  async function postAttendantRole() {
    try {
      if (!name || stringFormat.isEmptyOrSpaces(name)) {
        addToast({
          type: "warning",
          title: "Tipo de procedimento",
          description:
            "Para criar um tipo de procedimento é necessário preencher os campos destacados.",
        });
        setFieldError(true);
        setStartFocus(true);
        return;
      }

      const existName = stringFormat.verifyExistNameInArray(
        listProcedureCustomerType,
        name,
        element?.id,
      );

      if (existName) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "O nome já existe na base de dados",
        });
        setStartFocus(true);
        return;
      }

      dispatch(showLoading());

      const resultPost = await ProcedureCustomerTypeService.post(
        "",
        createBody(),
      );
      if (resultPost.status === true) {
        addToast({
          type: "success",
          title: "Tipo de procedimento cadastrado",
          description: "Tipo de procedimento foi cadastrado com sucesso.",
        });
        clear();
        await onLoadList();
      } else {
        if (resultPost.status === false && resultPost.severity) {
          addToast({
            type: "warning",
            title: "Aviso",
            description: resultPost.message,
          });
        } else {
          addToast({
            type: "warning",
            title: "Falha ao criar tipo de participante",
          });
        }
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Aviso",
        description: "Ocorreu um erro inesperado",
      });
    }

    dispatch(hideLoading());
  }

  async function putAttendantRole() {
    try {
      if (!name || stringFormat.isEmptyOrSpaces(name)) {
        addToast({
          type: "warning",
          title: "Tipo de participante",
          description:
            "Para atualizar um tipo de participante é necessário preencher os campos destacados.",
        });
        setFieldError(true);
        setStartFocus(true);
        return;
      }

      const existName = stringFormat.verifyExistNameInArray(
        listProcedureCustomerType,
        name,
        element?.id,
      );

      if (existName) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "O nome já existe na base de dados",
        });
        setStartFocus(true);
        return;
      }

      dispatch(showLoading());

      const resultPut = await ProcedureCustomerTypeService.put(
        "",
        createBody(),
      );

      if (resultPut.status === true) {
        addToast({
          type: "success",
          title: "Tipo de procedimento atualizado",
          description: "Tipo de procedimento foi atualizado com sucesso.",
        });
        clear();
        await onLoadList();
      } else {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPut.message,
        });
      }
    } catch (error) {
      addToast({
        type: "error",
        title: "Aviso",
        description: "Ocorreu um erro inesperado",
      });
    }

    dispatch(hideLoading());
  }

  return (
    <>
      <AdminArea
        title={"Tipo de procedimento"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={() => alert("click trash")}
        onClick={(item: any) => onClick(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        onChangeSituation={(value: any) => handleSitualtion(value)}
        permissionName={FORMS_ADMIN.PROCEDURE_CUS_TYPE}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={
          isEdit
            ? "Editando tipo de procedimento"
            : "Criar tipo de procedimento"
        }
        onClickAplicar={onClickAplicar}
        showIconDelete={false}
      >
        <CreateEditProcedureCustomerType
          onChangeName={setName}
          valueName={name}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          fieldError={fieldError}
          startFocus={startFocus}
        />
      </ModalRight>
    </>
  );
};
