import { AxiosError } from "axios";

import { LogOperation } from "../../utils/entities/logOperation";
import api from "../api";
import { returnResponse } from "../utils";

export interface IHearingFile {
  hearingId: number;
  type: number;
  title: string;
  filename: "inqueritodigitalssppc\\2023\\unidade_1\\foto\\Captura de Tela 2023-04-22 às 14.54.38.png";
  duration: number;
  size: number;
  fileOrder: number;
  hash: string; // uuid
  createIn: Date;
  updateIn: Date;
  upload: boolean;
}

export const hearingFileService = {
  getAll: async (hearingId: string) => {
    try {
      const { data } = await api.get(
        `/HearingFile/GetAllByHearingId?hearingId=${hearingId}`,
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
  getAllDownloadFiles: async (hearingId: number) => {
    try {
      const { data } = await api.get(
        `/HearingFile/GetAllDownloadsByHearingId?hearingId=${hearingId}`,
      );

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getTranscriptionByHearingFileId: async (hearingFileId: number) => {
    try {
      const { data } = await api.get(
        `/HearingTranscription/GetTranscriptionEmbeded`,
        {
          params: { hearingFileId },
        },
      );

      if (data.ok) {
        const transcriptionItem = Object.values(data.data).find(
          (item: any) => item?.transcription?.transcriptionData?.parts,
        ) as {
          transcription: {
            transcriptionData: {
              parts: any[];
              channels: any[] | null;
            };
          };
        };

        if (!transcriptionItem) {
          return {
            success: false,
            message: "Nenhuma transcrição encontrada.",
          };
        }

        const transcriptionParts =
          transcriptionItem.transcription.transcriptionData.parts;
        const channels =
          transcriptionItem.transcription.transcriptionData.channels;
        const item2 = data.data.item2;

        return {
          success: true,
          body: {
            transcriptionParts,
            channels,
            item2,
          },
        };
      } else {
        return {
          success: false,
          message: "Nenhuma transcrição encontrada.",
        };
      }
    } catch (error) {
      console.error("Erro ao buscar transcrição:", error);
      return {
        success: false,
        message: "Erro ao buscar a transcrição.",
      };
    }
  },

  uploadFile: async (containerName: string, file: any) => {
    try {
      const { data } = await api.post(
        `/hearingfile/uploadFile?container=${containerName}`,
        file,
        {
          onUploadProgress(progressEvent) {
            if (progressEvent && progressEvent.total) {
              const progress: number = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
            }
          },
        },
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
  add: async (payload: any, hearingId: any) => {
    try {
      const { data } = await api.post("/HearingFile/Add", {
        hearingId,
        ...payload,
      });

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
  patch: async (name: string, id: number) => {
    try {
      const device = LogOperation.getDevice();
      const ip = LogOperation.getMachineIP();
      const logPoliceUnitId = LogOperation.getPoliceUnitId();
      const logUserId = LogOperation.getUserId();

      const body = [
        {
          op: "replace",
          value: name,
          path: "title",
        },
        {
          op: "2",
          value: device,
          path: "device",
        },
        {
          op: "2",
          value: ip,
          path: "ip",
        },
        {
          op: "2",
          value: logPoliceUnitId,
          path: "logPoliceUnitId",
        },
        {
          op: "2",
          value: logUserId,
          path: "logUserId",
        },
      ];
      const { data } = await api.patch(`/HearingFile/Update?id=${id}`, body);

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
  delete: async (id: number) => {
    try {
      const { data } = await api.delete(`/HearingFile/Delete?id=${id}`);

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: true,
        body: {},
      };
    }
  },
};
