import axios from "axios";
import GitInfo from "react-git-info/macro";
import { getStore } from "store";

import api from "services/api";

import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "utils/sessionStorageEncrypt";

// Id	  Operation	  Description
// 1	  1	          Inserção
// 2	  2	          Atualização
// 3	  3	          Exclusão
// 4	  4	          Login
// 5	  5	          Logoff
// 6	  6	          Exportação
// 7	  7	          Importação
// 8	  8	          Gravação
// 9	  9	          Visualização
// 10	  10	        Publicação

export const LogOperation = {
  async postLogOperation(
    systemObject: string,
    operation: number,
    registerId: number,
  ) {
    const dateOperation = new Date().toLocaleString("en-US");
    try {
      const postLogger = {
        policeUnitId: this.getPoliceUnitId(),
        userId: this.getUserId(),
        registerId: registerId,
        date: dateOperation,
        systemObject: systemObject,
        operation: operation,
        device: this.getDevice(),
        ip: this.getMachineIP(),
      };
      await api.post("/LogOperation/Add", postLogger);
    } catch (errorrr) {
      console.log("errorrr", errorrr);
    }
  },
  getUserId: (): any => {
    const user = getValueFromlocalStorage("@Kenta:user");
    let userId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      userId = user_parsed.id;
    }

    return userId;
  },
  getCustomerId: (): number => {
    const user = getValueFromlocalStorage("@Kenta:user");
    let userCustomerId: any = null;
    if (user) {
      const user_parsed = JSON.parse(user);
      userCustomerId = user_parsed.customerId;
    }

    return userCustomerId;
  },
  getDevice: (): string => {
    return "PC";
  },
  getPoliceUnitId: () => {
    try {
      const state = getStore().getState();

      const { policeUnit } = state?.policeUnit || null;
      const unitStorage = getValueFromlocalStorage(
        "@Kenta:unidade_selecionada",
      );

      if (unitStorage) {
        const unitObject = JSON.parse(unitStorage);
        return unitObject.unitId;
      }

      if (!unitStorage && policeUnit?.unitId) {
        insertTolocalStorage(
          "@Kenta:unidade_selecionada",
          String(policeUnit?.unitId),
        );
      }

      return policeUnit?.unitId || null;
    } catch (error) {
      console.log(error);
    }
  },
  getMachineIP: (): any => {
    return localStorage.getItem("MachineIP");
  },

  setMachineIP: async () => {
    try {
      const res = await axios.get("https://api64.ipify.org?format=json");

      if (res.data && res.data.ip) {
        localStorage.setItem("MachineIP", res.data.ip);
      } else {
        throw new Error("IP não encontrado na resposta");
      }
    } catch (error: any) {
      console.error("Erro ao buscar IP público:", error);
      localStorage.setItem("MachineIP", "Não encontrado");
    }
  },

  getGithubInfo: () => {
    const gitInfo = GitInfo();

    const formatDate = format(new Date(gitInfo.commit.date));
    const dateTimeGit = format24Hour(
      formatDate.dd,
      formatDate.mm,
      formatDate.yyyy,
      formatDate.HH,
      formatDate.MM,
      formatDate.SS,
    );

    return {
      branch: gitInfo.branch,
      tags: gitInfo.tags,
      date: dateTimeGit,
      hash: gitInfo.commit.hash,
      message: gitInfo.commit.message,
      shortHash: gitInfo.commit.shortHash,
      version: createVersion(),
    };
  },
};

const formatData = (input: any) => {
  if (input > 9) {
    return input;
  } else return `0${input}`;
};

const formatHour = (input: any) => {
  if (input > 12) {
    return input - 12;
  }
  return input;
};

const format24Hour = (
  dd: any,
  mm: any,
  yyyy: any,
  HH: any,
  MM: any,
  SS: any,
) => {
  return `${dd}/${mm}/${yyyy} ${HH}:${MM}:${SS}`;
};

function format(date: any) {
  const newFormat = {
    dd: formatData(date.getDate()),
    mm: formatData(date.getMonth() + 1),
    yyyy: date.getFullYear(),
    HH: formatData(date.getHours()),
    hh: formatData(formatHour(date.getHours())),
    MM: formatData(date.getMinutes()),
    SS: formatData(date.getSeconds()),
  };
  return newFormat;
}

function createVersion() {
  return (
    "1." +
    new Date().getDate() +
    "." +
    (new Date().getHours() + new Date().getMinutes())
  );
}
