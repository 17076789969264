import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import moment from "moment";
import Avatar from "react-avatar";
import { useDispatch } from "react-redux";
import Webcam from "react-webcam";
import { v4 } from "uuid";

import { useToast } from "hooks/toast";

import { updateUser } from "store/modules/user/actions";
import { useAppSelector } from "store/rootReducer";

import { storageBaseUrl } from "services/api";
import { FileService } from "services/file";
import { userService } from "services/users";

import CameraIcon from "assets/svg/camera-arquivos.svg";

import { LogOperation } from "utils/entities/logOperation";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "utils/sessionStorageEncrypt";

import { BoxWebCamImage, ButtonTakePhoto, WebCamContainer } from "./styles";
import ModalSelectOrTakePicture from "../ModalSelectOrTakePicture";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

interface IPhotoWebCam {
  changePicture: boolean;
  setChangePicture: (e: boolean) => void;
}

const PhotoWebCam = forwardRef(
  ({ changePicture, setChangePicture }: IPhotoWebCam, ref) => {
    const [profileImage, setProfileImage] = useState<File | null>(null);
    const [isCameraMode, setIsCameraMode] = useState(false);
    const [showModalPicture, setShowModalPicture] = useState(false);
    const [preview, setPreview] = useState<string>("");
    const webcamRef = useRef<Webcam>(null);
    const dispatch = useDispatch();
    const { addToast } = useToast();

    const { policeUnit } = useAppSelector((state) => state.policeUnit);
    const { user } = useAppSelector((state) => state.user);

    const { selectedVideo } = useAppSelector((state) => state.mediaDevices);

    const updateUserStorage = async () => {
      const storage = getValueFromlocalStorage("@Kenta:user");
      const user = storage ? JSON.parse(storage) : null;

      if (user) {
        const { data } = await userService.get(LogOperation.getUserId());
        const updatedUser = {
          ...data,
          picture: `${data.picture.replace(/\\/g, "/")}?v=${Date.now()}`,
          hash: user.hash,
        };

        insertTolocalStorage("@Kenta:user", JSON.stringify(updatedUser));
        dispatch(updateUser(updatedUser));
        setChangePicture(!changePicture);
      }
    };

    const handleUploadImage = async () => {
      if (!profileImage) return;

      try {
        const extension =
          profileImage.name.match(/\.[a-z0-9]{2,4}$/i)?.[0] || ".jpg";
        const filename = `${v4()}_${moment().format(
          "DDMMYYYYHHmmss"
        )}${extension}`;
        const filePath = `inqueritodigitalssppc\\${new Date().getFullYear()}\\unidade_${LogOperation.getCustomerId()}\\photo\\${filename}`;

        const formData = new FormData();
        formData.append("content", profileImage, filePath);

        const response = await FileService.upload(formData);

        if (response.success) {
          addToast({
            type: "success",
            title: "Aviso",
            description: "Foto atualizada com sucesso",
          });
          await updateUserStorage();
          setPreview("");
        } else {
          throw new Error("Upload failed");
        }
      } catch {
        addToast({
          type: "error",
          title: "Aviso",
          description: "Falha ao enviar o arquivo",
        });
      }
    };

    const capture = useCallback(() => {
      if (!webcamRef.current) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "Camera não encontrada",
        });
        return;
      }

      const imageSrc = webcamRef.current.getScreenshot();
      if (!imageSrc) {
        addToast({
          type: "error",
          title: "Erro",
          description: "Falha ao capturar imagem",
        });
        return;
      }

      const canvas = document.createElement("canvas");
      const image = new Image();

      image.onload = function () {
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext("2d");
        if (context) {
          context.drawImage(image, 0, 0, image.width, image.height);
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], "capturaImage.png", {
                type: "image/png",
              });
              setProfileImage(file);
              setPreview(URL.createObjectURL(file));
            }
          }, "image/png");
        }
      };
      image.src = imageSrc;
      setIsCameraMode(false);

      return () => {
        if (preview) URL.revokeObjectURL(preview);
      };
    }, [webcamRef, preview, addToast, setProfileImage]);

    const updateFotoByComputer = (file: File) => {
      if (!file) return;

      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      setProfileImage(file);
      setShowModalPicture(false);
    };

    useImperativeHandle(ref, () => ({
      handleUploadImage,
    }));

    const handleModalTakePicture = () => {
      if (preview) {
        setProfileImage(null);
        setPreview("");
        setShowModalPicture(false);
      } else {
        setIsCameraMode(true);
        setShowModalPicture(false);
      }
    };

    const renderDefaultView = () => {
      return (
        <>
          <WebCamContainer>
            {preview ? (
              <img src={preview} alt="Preview" style={imageStyle} />
            ) : user?.picture ? (
              <img
                src={`${storageBaseUrl}/${policeUnit?.containerName}/${
                  user?.picture
                }?v=${Date.now()}`}
                alt="User"
                style={imageStyle}
              />
            ) : (
              <Avatar
                name={user?.name || "Avatar"}
                size="150"
                round
                style={imageStyle}
              />
            )}
          </WebCamContainer>
          <ButtonTakePhoto>
            <img
              src={CameraIcon}
              alt="Open camera options"
              onClick={() => setShowModalPicture(true)}
              style={buttonStyle}
            />
          </ButtonTakePhoto>
        </>
      );
    };

    const renderCameraView = () => (
      <>
        <WebCamContainer>
          <Webcam
            audio={false}
            height={150}
            ref={webcamRef}
            screenshotQuality={1}
            screenshotFormat="image/jpeg"
            width={150}
            videoConstraints={{
              ...videoConstraints,
              deviceId: selectedVideo?.id,
            }}
            style={imageStyle}
          />
        </WebCamContainer>
        <ButtonTakePhoto>
          <img
            src={CameraIcon}
            alt="Capture"
            onClick={capture}
            style={buttonStyle}
          />
        </ButtonTakePhoto>
      </>
    );

    return (
      <BoxWebCamImage>
        {isCameraMode ? renderCameraView() : renderDefaultView()}
        {showModalPicture && (
          <ModalSelectOrTakePicture
            preview={preview}
            takePicture={handleModalTakePicture}
            onClose={() => setShowModalPicture(false)}
            setFileImg={updateFotoByComputer}
          />
        )}
      </BoxWebCamImage>
    );
  }
);

PhotoWebCam.displayName = "PhotoWebCam";

const imageStyle: React.CSSProperties = {
  height: 150,
  width: 150,
  borderRadius: "50%",
  objectFit: "cover",
};

const buttonStyle: React.CSSProperties = {
  height: 40,
  backgroundColor: "white",
  borderRadius: "50px",
};

export default PhotoWebCam;
