import React, { useEffect, useState } from "react";

import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  Label,
  ListItem,
} from "./styles";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import ArrowUp from "../../assets/svg/arrow-up.svg";
import Selected from "../../assets/svg/Selected.svg";
import { CircleBadge } from "../Badges/Circle";

export interface IDropDown {
  name: string;
  id: number;
  selected?: boolean;
}

export interface iDropdown {
  labelName: string;
  options: IDropDown[];
  defaultValue?: any;
  setSelected: any;
  editDropdown?: any;
  required?: boolean;
  error?: boolean;
  width?: string;
}

export const DropdownUnit: React.FC<iDropdown> = ({
  labelName,
  options,
  setSelected,
  editDropdown,
  defaultValue,
  required = false,
  error,
  width = "385px",
}) => {
  const defaultSelectedOption = "Selecione.";
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    editDropdown ? editDropdown : defaultValue || defaultSelectedOption,
  );

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: any, option?: any) => () => {
    setSelectedOption(value);
    setSelected(option);
    setIsOpen(false);

    if (option) {
      setSelected(option);
    }
  };
  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  return (
    <DropDownContainer width={width}>
      <Label>{labelName}</Label>
      {required && <CircleBadge radius="50px" widthHeigt="8px" />}
      <DropDownHeader
        id="drop_header"
        disable={!options || options.length <= 0}
        error={error}
        focus={isOpen}
        onClick={() => {
          if (options && options.length > 0) toggling();
        }}
        selected={selectedOption === defaultSelectedOption ? false : true}
      >
        <span>{selectedOption || "---"}</span>
        <img
          className="icon"
          src={isOpen ? ArrowUp : ArrowDown}
          onClick={() => {
            if (options && options.length > 0) toggling();
          }}
          style={{ cursor: options?.length > 0 ? "pointer" : "not-allowed" }}
          alt=""
        />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList width={width}>
            {options.map((item: any, index: any) => {
              return (
                <ListItem
                  onClick={onOptionClicked(item.name || item, item)}
                  key={index}
                  selected={item.name === selectedOption}
                >
                  {item.name || item}
                  {item.name === selectedOption ? (
                    <img src={Selected} alt="" />
                  ) : null}
                </ListItem>
              );
            })}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};
