import { AxiosError } from "axios";

import { PermissionResponse, UserCustomerProfileResponse } from "./types";
import api from "../api";
import { returnResponse } from "../utils";

export type IAddUserCustomerPoliceUnit = {
  id?: number;
  userId: number;
  policeUnitId: number;
  userCustomerProfileId: number;
  expireIn: string | null;
  active: boolean;
  device: string;
  ip: string;
  logPoliceUnitId: number;
  logUserId: number;
};

export const UserCustomerProfileService = {
  getAllByUserCustomer: async (
    authToken: string | null,
    userCustomerId: number,
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados de perfil sem estar autenticado.",
      };
    }

    if (!userCustomerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados de perfil sem o identificador do perfil",
      };
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { UserCustomerId: userCustomerId },
      };
      const { data } = await api.get<UserCustomerProfileResponse>(
        "/UserCustomerProfile/GetUserCustomerProfile",
        config,
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  /**
   *
   * @param authToken token of auth
   * @param customerId
   * @param userCustomerProfileId
   * @returns
   */
  getAllUserProfilePermissionsByParams: async (
    authToken: string | null,
    customerId: number | undefined,
    userCustomerProfileId: number,
  ) => {
    if (!authToken) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados de permissões sem estar autenticado.",
      };
    }

    if (!customerId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados de permissões sem o usuario selecionado",
      };
    }

    if (!userCustomerProfileId) {
      return {
        status: false,
        message:
          "Não é possível recuperar os dados de permissões sem o perfil selecionado",
      };
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { UserCustomerProfileId: userCustomerProfileId, customerId },
      };
      const { data } = await api.get<PermissionResponse>(
        "SystemObjectUserCustomerProfile/GetAllByObjectsUserCustomerProfile",
        config,
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getPermissionToSeeIfHasPermissionClassified: async (
    userId: number,
    userCustomerProfile: number,
    policeUnitId: number,
  ) => {
    try {
      const { data } = await api.get(
        `/UserCustomer/CheckUserAdmin?userId=${userId}&UserCustomerProfileId=${userCustomerProfile}&policeUnitId=${policeUnitId}`,
      );

      return {
        success: true,
        body: !!data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
  add: async (body: IAddUserCustomerPoliceUnit) => {
    try {
      const { data } = await api.post("/UserCustomerPoliceUnit/Add", body);

      return {
        success: true,
        body: data.data,
        messsage: data.message || "Adicionado com sucesso",
      };
    } catch (error: any) {
      return {
        success: false,
        body: null,
        messsage:
          error?.response?.data?.message ||
          "Não foi possivel adicionar a unidade de polícia",
      };
    }
  },
};
