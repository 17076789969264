import React, { useEffect, useRef } from "react";

import { DrawerDefault } from "@kentarepo/kcf-assets";
import { MdMic } from "react-icons/md";
import { Tooltip } from "react-tooltip";

import { useToast } from "hooks/toast";
import { useMediaPermissions } from "hooks/useMediaPermissions";

import {
  getDevicesThunk,
  changeMicThunk,
  changeVideoThunk,
  changeAudioThunk,
  persistPreferencesThunk,
  restorePreferencesThunk,
} from "store/modules/mediaDevices/actions/thunks";
import { useAppDispatch, useAppSelector } from "store/rootReducer";

import { DropdownGlobal } from "components/DropdownGlobal";
import { ContainerMic } from "components/MicLevelDisplay/styles";
import VideoCamera from "components/VideoCamera";
import { videoConstraints } from "components/VideoCamera/constants";

import { Container } from "./styles";

const SelectAudioCamera: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();

  const toast = useToast();

  const {
    micDevices,
    videoDevices,
    audioDevices,
    selectedMic,
    selectedVideo,
    selectedAudio,
    micLevel,
  } = useAppSelector((state) => state.mediaDevices);

  const permissions = useMediaPermissions();

  const fetchingPreferences = useRef(false);

  useEffect(() => {
    const fetchPreferences = async () => {
      fetchingPreferences.current = true;
      await dispatch(restorePreferencesThunk());
      fetchingPreferences.current = false;
    };

    if (open && !fetchingPreferences.current) {
      fetchPreferences();
    }
  }, [open, dispatch]);

  useEffect(() => {
    const handleDeviceChange = () => {
      console.log("change@@@");
      dispatch(getDevicesThunk());
    };

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange,
      );
    };
  }, [dispatch]);

  const onApplyDevices = async () => {
    try {
      await dispatch(persistPreferencesThunk()).unwrap();
      toast.addToast({
        type: "success",
        title: "Configuração de dispositivos",
        description: "Configuração de dispositivos salva com sucesso",
      });
      setOpen(false);
    } catch {}
  };

  return (
    <DrawerDefault
      onClose={() => setOpen(false)}
      setOpenDrawer={() => {}}
      title="Configuração de dispositivos"
      onApply={permissions.mic ? onApplyDevices : undefined}
      openDrawer={open}
      disabled={!permissions.mic}
    >
      <Container>
        {!permissions.mic && <Tooltip id="mic-tooltip" place="top" />}
        <div
          data-tooltip-id={!permissions.mic ? "mic-tooltip" : undefined}
          data-tooltip-content="Permissão de acesso ao microfone necessária"
        >
          <DropdownGlobal
            wantAnObject
            width="380px"
            defaultValue={selectedMic?.label}
            showDefaultValue={Boolean(selectedMic?.label)}
            options={micDevices}
            labelName="Microfone"
            setSelected={(e: any) => dispatch(changeMicThunk(e.id))}
          />
          <div className="container">
            {Array.from({ length: 30 }).map((_, i) => (
              <div
                className="bar"
                key={i}
                style={{
                  backgroundColor:
                    Math.floor(micLevel % 30) > i ? "#A0A0A0" : undefined,
                }}
              />
            ))}
          </div>
          <ContainerMic>
            <MdMic color="blue" />
            <input
              style={{ width: 440 }}
              id="volume"
              min="0"
              max="100"
              value={Number(micLevel)}
              disabled
              type="range"
            />
          </ContainerMic>
        </div>

        {!permissions.audio && <Tooltip id="audio-tooltip" place="top" />}
        <div
          data-tooltip-id={!permissions.audio ? "audio-tooltip" : undefined}
          data-tooltip-content="Permissão de acesso ao áudio necessária"
        >
          <DropdownGlobal
            wantAnObject
            width="380px"
            defaultValue={selectedAudio?.label}
            showDefaultValue={Boolean(selectedAudio?.label)}
            options={audioDevices}
            labelName="Áudio"
            setSelected={(e: any) => dispatch(changeAudioThunk(e.id))}
          />
        </div>

        {!permissions.camera && <Tooltip id="video-tooltip" place="top" />}
        <div
          data-tooltip-id={!permissions.camera ? "video-tooltip" : undefined}
          data-tooltip-content="Permissão de acesso à câmera necessária"
        >
          <DropdownGlobal
            wantAnObject
            width="380px"
            defaultValue={
              videoDevices.find((d) => d.id === selectedVideo?.id)?.label
            }
            showDefaultValue={Boolean(
              videoDevices.find((d) => d.id === selectedVideo?.id)?.label,
            )}
            options={videoDevices}
            labelName="Vídeo"
            setSelected={(e: any) => dispatch(changeVideoThunk(e.id))}
          />
        </div>

        <VideoCamera
          videoConstraints={{
            ...videoConstraints,
            deviceId: selectedVideo?.id
              ? { exact: selectedVideo.id }
              : undefined,
          }}
        />
      </Container>
    </DrawerDefault>
  );
};

export default SelectAudioCamera;
