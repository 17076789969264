import { useState } from "react";

import { ContainerInputDates, Content, DropContainer } from "./style";
import { Button } from "../../components/Button";
import { DropdownGlobal } from "../../components/DropdownGlobal";
import { InputGeneral } from "../../components/Input";
import { InputDateGlobal } from "../../components/InputDateGlobal";
import { InputSwitch } from "../../components/InputSwitch";
import { useToast } from "../../hooks/toast";

export function FilterProcedure({
  isOpend,
  setIsOpend,
  searchProcedureByFilters,
  setItemData,
}: any) {
  const [procedureNumber, setProcedureNumber] = useState<any>();
  const [participante, setParticipante] = useState<string>("");
  const [defaultStatus, setDefaultStatus] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [endError, setEndError] = useState<boolean>(false);
  const [startError, setStartError] = useState<boolean>(false);

  const { addToast } = useToast();

  const optionsDropDownStatus = [
    {
      id: "A",
      name: "Agendado",
    },
    {
      id: "G",
      name: "Em andamento",
    },
    {
      id: "E",
      name: "Encerrada",
    },
    {
      id: "P",
      name: "Publicada",
    },
  ];

  const handleStartDateChange = (value: string) => {
    const selectedStartDate = value;
    setStartDate(selectedStartDate);
    if (endDate && new Date(selectedStartDate) > new Date(endDate)) {
      setEndDate("");
    }
  };

  const handleEndDateChange = (value: string) => {
    const selectedEndDate = value;
    if (new Date(selectedEndDate) >= new Date(startDate!)) {
      setEndDate(selectedEndDate);
    } else {
      setEndDate("");
    }
  };

  const getDefaultStatus = (key?: string) => {
    if (!key || key === "" || key == "Selecione") return "Selecione";

    return optionsDropDownStatus.filter((item) => item.id === key)[0].name;
  };

  const buildObjectFilter = () => {
    const filter: any = {};

    if (!procedureNumber || Number(procedureNumber) === 0)
      filter.procedureNumber = null;
    else {
      if (procedureNumber) filter.procedureNumber = Number(procedureNumber);
    }

    if (startDate !== "" && endDate !== "") {
      filter.startDate = new Date(startDate);
      filter.finishDate = new Date(endDate);
    }

    if (defaultStatus !== "" && defaultStatus !== "Selecione")
      filter.status = defaultStatus;

    if (participante !== "") filter.attendantName = participante;

    if (Object.keys(filter).length <= 0) return null;

    filter.active = ativo;

    return filter;
  };

  function onClickSearch() {
    if ((startDate && !endDate) || (endDate && !startDate)) {
      if (!endDate) setEndError(true);
      if (!startDate) setStartError(true);

      if (startDate) setStartError(false);
      if (endDate) setEndError(false);

      return addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para fazer a filtragem por data é necessário preencher os dois campos",
      });
    }

    if (startDate) setStartError(false);
    if (endDate) setEndError(false);

    searchProcedureByFilters(buildObjectFilter());
    setIsOpend(false);
  }

  function onClickCleanFilters() {
    setProcedureNumber("");
    setStartDate("");
    setEndDate("");
    setDefaultStatus("Selecione");
    setParticipante("");
    searchProcedureByFilters(null);
    setIsOpend(false);
  }

  return (
    <Content isOpen={isOpend}>
      {isOpend && (
        <DropContainer>
          <InputGeneral
            value={procedureNumber}
            onChange={(e) => {
              if (e.length === 0) {
                setProcedureNumber("");
                return;
              }

              if (e.length < 9) setProcedureNumber(Number(e));
            }}
            type="number"
            label="Número do procedimento"
            required={false}
          />
          <ContainerInputDates>
            <InputDateGlobal
              // error={!props.date && props.errorFieldHearing}
              //error={props.errorFieldHearing}
              error={startError}
              value={startDate}
              //onChange={props.setDateSelected}
              onChange={(e) => handleStartDateChange(e)}
              type="date"
              label="Período data inicial"
              required={false}
            />
            <InputDateGlobal
              marginLeft={12}
              value={endDate}
              onChange={(e) => handleEndDateChange(e)}
              type="date"
              label="Período Data Final"
              error={endError}
              required={false}
            />
          </ContainerInputDates>
          <DropdownGlobal
            options={optionsDropDownStatus as any}
            labelName="Status"
            setSelected={setDefaultStatus}
            defaultValue={getDefaultStatus(defaultStatus)}
            error={false}
            width="350px"
          />
          <InputGeneral
            value={participante}
            onChange={(e) => {
              setParticipante(e);
            }}
            type="text"
            label="Participante"
            required={false}
            startFocus={false}
          />
          <InputSwitch
            text="Ativo"
            checked={ativo}
            onChange={() => setAtivo(!ativo)}
          />
          <Button
            styles={{ marginTop: 10, marginRight: 4 }}
            color="forth"
            onClick={onClickCleanFilters}
          >
            Limpar
          </Button>
          <Button
            styles={{ marginTop: 10 }}
            color="forth"
            onClick={onClickSearch}
          >
            Pesquisar
          </Button>
        </DropContainer>
      )}
    </Content>
  );
}
