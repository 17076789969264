import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { ListEmpty } from "../ListEmpty";
import "./styles.css";
import { ContainerList, ListItems, ListPagination } from "./styles";

import ReactPaginate from "react-paginate";

interface IReactPaginate {
  list: Array<any>;
  title: string;
  height?: string;
}

export const ReactPaginateKenta = ({ list, title, height }: IReactPaginate) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [currentPage, setCurrentPage] = useState<number>(
    parseInt(searchParams.get("page") || "0", 10),
  );

  const [valeuRegisterPerPage, setValeuRegisterPerPage] = useState<number>(5);
  const [previousLabel, setPreviousLabel] = useState<string>("<");
  const [nextLabel, setNextLabel] = useState<string>(">");

  const offset = currentPage * valeuRegisterPerPage;
  const currentPageData = list.slice(offset, offset + valeuRegisterPerPage);
  const pageCount: number = Math.ceil(list.length / valeuRegisterPerPage);

  useEffect(() => {
    const paginationLocal = localStorage.getItem("PaginationList");
    setValeuRegisterPerPage(paginationLocal ? parseInt(paginationLocal) : 10);

    if (pageCount === 1) {
      setPreviousLabel("");
      setNextLabel("");
    }
  }, [pageCount]);

  useEffect(() => {
    if (currentPage >= pageCount && currentPage > 0 && pageCount > 0) {
      setCurrentPage(pageCount - 1);
    }
  }, [pageCount]);

  useEffect(() => {
    if (pageCount === 0) {
      setPreviousLabel("");
      setNextLabel("");
    } else if (pageCount > 1) {
      setPreviousLabel(currentPage > 0 ? "<" : "");
      setNextLabel(currentPage < pageCount - 1 ? ">" : "");
    }
  }, [pageCount, currentPage]);

  function handlePageClick(selectedPage: number) {
    setCurrentPage(selectedPage);
    updateQueryString(selectedPage);
    formartPreviousLabel(selectedPage);
    formartNextLabel(selectedPage);
  }

  function updateQueryString(page: number) {
    const params = new URLSearchParams(location.search);
    params.set("page", page.toString());
    navigate(`?${params.toString()}`, { replace: true });
  }

  function formartPreviousLabel(selectedPage: number) {
    setPreviousLabel(selectedPage === 0 ? "" : "<");
  }

  function formartNextLabel(selectedPage: number) {
    setNextLabel(selectedPage + 1 === pageCount ? "" : ">");
  }

  return (
    <>
      <ContainerList height={height}>
        {list.length > 0 ? (
          <div>
            <ListItems>
              <div className="menudivList">{currentPageData}</div>
            </ListItems>
          </div>
        ) : (
          <ListEmpty title={title} />
        )}
      </ContainerList>
      <div>
        <ListPagination>
          <ReactPaginate
            previousLabel={previousLabel}
            nextLabel={nextLabel}
            pageCount={pageCount > 1 ? pageCount : 0}
            onPageChange={(e) => handlePageClick(e.selected)}
            containerClassName={"pagination"}
            previousLinkClassName={
              previousLabel === "<"
                ? "pagination__link"
                : "previusAndNextDisableCursor"
            }
            nextLinkClassName={
              nextLabel === ">"
                ? "pagination__link"
                : "previusAndNextDisableCursor"
            }
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            forcePage={currentPage}
          />
        </ListPagination>
      </div>
    </>
  );
};
