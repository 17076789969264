import React, { useEffect, useState } from "react";

import { AdmProps } from "./@types";
import { IlistPerYer, IlistReport } from "./dtos";
import {
  Container,
  //body
  ContainerBody,
  ContainerList,
  ContainerTitleWithNumber,
  Title,
} from "./styles";
import { QuantityComponent } from "../../../components/QuantityComponent";
import { TitleContainer } from "../../../components/Toast/styles";
import { CardReportItem } from "../../../features/CardReportItem";
import { CardReportPerYer } from "../../../features/CardReportPerYer";
import { HeaderContainer } from "../../../features/HeaderHearing/styles";
import { reportService } from "../../../services/report";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { ButtonGlobal } from "../../ButtonGlobal/ButtonGlobal";
import { DropdownReport } from "../../DropdownReport";
import { InputDateGlobal } from "../../InputDateGlobal";
import { ReactPaginateKenta } from "../../ReactPaginate";

export function ReportArea({
  title,
  list,
  onClick,
  onChangeSituation,
  initialDate,
  endDate,
  setInitialDate,
  setEndDate,
}: AdmProps) {
  const [options, setOptions] = useState<any[]>([]);
  const user = getValueFromlocalStorage("@Kenta:user");
  let userCustomerId: any = null;
  let userId: any = null;
  if (user) {
    const user_parsed = JSON.parse(user);
    userCustomerId = user_parsed.customerId;
    userId = user_parsed.id;
  }
  const [listEmptyMessage, setListEmptyMessage] = useState<
    string | undefined
  >();
  const inputRef = React.useRef<HTMLInputElement>(null);
  async function getPoliceUnit() {
    const response = await reportService.getAllPoliceUnitByUserId(userId);
    setOptions(response.data.data);
  }

  useEffect(() => {
    getPoliceUnit();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const [valeuRegisterPerPage, setValeuRegisterPerPage] = useState<number>(10);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const paginationLocal = localStorage.getItem("PaginationList");

    if (paginationLocal) {
      setValeuRegisterPerPage(parseInt(paginationLocal));
    } else {
      setValeuRegisterPerPage(10);
    }
  }, []);

  const listCardReportItem = list
    //.slice(offset, offset + valeuRegisterPerPage)
    .map((item: IlistReport, index: number) => (
      <CardReportItem
        id
        key={index}
        policeUnitId={item?.id}
        policeUnitName={item?.policeUnitName}
        date={item.date}
        procedureNumber={item?.procedureNumber}
        source={item?.source}
        type={item?.type}
        notPointer
      />
    ));

  const listByYear = list.map((item: IlistPerYer, index: number) => (
    <CardReportPerYer
      key={index}
      status={item.status}
      total={item.total}
      year={item.year}
      notPointer
    />
  ));

  let previousLabel = "<";
  let nextLabel = ">";

  if (listCardReportItem.length === 0) {
    previousLabel = "";
    nextLabel = "";
  }

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <ContainerTitleWithNumber>
            <Title>{title}</Title>
            <QuantityComponent value={list.length > 0 ? list.length : 0} />
          </ContainerTitleWithNumber>
          {/* {create && <AddButton onClick={headerAddButtonOnClick} />} */}
        </TitleContainer>
      </HeaderContainer>
      <ContainerBody>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <div style={{ marginTop: 40 }}>
            <DropdownReport
              labelName="Unidade de Polícia"
              options={options}
              setSelected={(value: any) => onChangeSituation(value!)}
              height="24px"
              width="320px"
              required={false}
            />
          </div>
          {title !== "Procedimentos por ano" && (
            <>
              <div style={{ marginLeft: 20, marginTop: 40 }}>
                <InputDateGlobal
                  label="Data inicial"
                  required={false}
                  type={"date"}
                  value={initialDate}
                  onChange={setInitialDate}
                  disabled={false}
                />
              </div>
              <div style={{ marginLeft: 20 }}>
                <InputDateGlobal
                  label="Data Final"
                  required={false}
                  type={"date"}
                  value={endDate}
                  onChange={setEndDate}
                  disabled={false}
                />
              </div>
            </>
          )}
          <div style={{ marginLeft: 12, marginBottom: 28 }}>
            <ButtonGlobal
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={() => {
                onClick("");
                if (list.length <= 0) {
                  setListEmptyMessage("dado");
                }
              }}
            >
              Gerar relatório
            </ButtonGlobal>
          </div>
        </div>
        {title === "Procedimentos por delegacia" && (
          <ContainerList>
            {list.length > 0 && (
              <>
                <CardReportItem
                  id
                  policeUnitId={"ID"}
                  policeUnitName={"Unidade de Polícia"}
                  date={"Data"}
                  procedureNumber={"Processo"}
                  source={"Origem"}
                  type={"Tipo"}
                  background={true}
                  notPointer
                />
              </>
            )}
            {listEmptyMessage && list ? (
              <ReactPaginateKenta
                list={listCardReportItem}
                title={title}
                height="40vh"
              />
            ) : null}
          </ContainerList>
        )}
        {title === "Procedimentos por ano" && (
          <ContainerList>
            {list.length > 0 && (
              <CardReportPerYer
                year="Ano"
                status="Status"
                total="Total"
                notPointer
                background
              />
            )}
            {listEmptyMessage && list ? (
              <ReactPaginateKenta
                list={listByYear}
                title={title}
                height="50vh"
              />
            ) : null}
          </ContainerList>
        )}
      </ContainerBody>
    </Container>
  );
}
