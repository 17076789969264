import React, { useEffect, useState } from "react";

import { SHA1 as sha1 } from "crypto-js";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  Container,
  ContainerBar,
  ContainerDescription,
  ContainerTitle,
} from "./style";
import { AuthScreen } from "..";
import { InputAuth } from "../../../components/Login/Input";
import BarComponent from "../../../components/StrongBar";
import { Typhography } from "../../../components/Typhography";
import { useToast } from "../../../hooks/toast";
import { PasswordService } from "../../../services/password";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { Button } from "../styles";

const STEPS = {
  CREATE_NEW_PASSWORD: 1,
  FINAL_STEP: 2,
};

type typesInput = "email" | "password" | "text" | undefined;
type strlvl = "weak" | "medium" | "strong";

export function CreatePassword() {
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [strongLvl, setStrongLvl] = useState<strlvl>("weak");
  const [passwordType, setPasswordType] = useState<typesInput>("password");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [step, setStep] = useState(STEPS.CREATE_NEW_PASSWORD);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { guid } = useParams();
  const { addToast } = useToast();

  useEffect(() => {}, []);

  function isStrongPassword(password: string): boolean {
    // Check if password is at least 8 characters long
    if (password.length < 8) {
      setStrongLvl("weak");
      return false;
    }

    // Check if password contains at least one lowercase letter, one uppercase letter, one digit, and one special character
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const digitRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*()\-_=+[\]{};':"\\|,.<>/?]/;

    if (
      !lowercaseRegex.test(password) ||
      !uppercaseRegex.test(password) ||
      !digitRegex.test(password) ||
      !specialCharRegex.test(password)
    ) {
      setStrongLvl("medium");
      return false;
    }
    setStrongLvl("strong");
    // Password meets all criteria for a strong password
    return true;
  }

  async function submitNewPassword() {
    if (!isStrongPassword(password) || !isStrongPassword(passwordConfirm)) {
      setErrorPassword(true);
      addToast({
        type: "warning",
        title: "Senha inválida",
        description:
          "Para continuar insira a senha com a regra determinada acima",
      });
      return;
    }
    if (password !== passwordConfirm) {
      setErrorPassword(true);
      addToast({
        type: "error",
        title: "Senha inválida",
        description: "As senhas não correspondem",
      });
      return;
    }
    dispatch(showLoading());

    try {
      if (guid) {
        const response = await PasswordService.changePassword(
          sha1(password).toString().toUpperCase(),
          guid,
        );
        if (!response.status) {
          setErrorPassword(true);
          addToast({
            type: "warning",
            title: "Aviso",
            description: response.message,
          });
        } else {
          await LogOperation.postLogOperation(
            "UserCustomerAuthentication",
            2,
            0,
          );
          addToast({
            type: "success",
            title: "Sucesso ao alterar a senha",
            description: "Sua senha foi alterada com sucesso.",
          });
          navigate("/");
        }
      }
    } catch (err) {
      console.log("err.status", err);
    }

    dispatch(hideLoading());
  }

  return (
    <AuthScreen>
      {/* First STEP */}
      {step === STEPS.CREATE_NEW_PASSWORD ? (
        <Container>
          <ContainerTitle>
            <Typhography fontStyle="bold" size="very-bigger">
              Crie uma nova senha
            </Typhography>
          </ContainerTitle>
          <ContainerDescription>
            <Typhography fontStyle="regular" size="medium">
              Dica: utilize maiúsculas, minúsculas, números e caracteres
              especiais (@ & $ % #) para criar uma senha forte.
            </Typhography>
          </ContainerDescription>
          <InputAuth
            id="new_password"
            maxLength={200}
            width={380}
            error={errorPassword}
            label="Digite a senha"
            disabled={loading}
            value={password}
            setType={(type: typesInput) => setPasswordType(type)}
            icon={true}
            type={passwordType}
            onChange={(password: string) => {
              setPassword(password);
              isStrongPassword(password);
              if (errorPassword) {
                setErrorPassword(false);
              }
            }}
          />
          <ContainerBar>
            <BarComponent strongLevel={strongLvl} />
          </ContainerBar>
          <InputAuth
            id="repeat_password"
            maxLength={200}
            width={380}
            error={errorPassword}
            label="Repita a senha"
            disabled={loading}
            value={passwordConfirm}
            setType={(type: typesInput) => setPasswordType(type)}
            icon={true}
            type={passwordType}
            onChange={(passwordConfirm: string) => {
              setPasswordConfirm(passwordConfirm);
              if (errorPassword) {
                setErrorPassword(false);
              }
            }}
          />
          <Button
            disabled={loading}
            type="button"
            onClick={() => {
              submitNewPassword();
            }}
          >
            Continuar
          </Button>
        </Container>
      ) : null}
      {/* Secod step */}
      {step === STEPS.FINAL_STEP ? (
        <Container style={{ height: 140 }}>
          <ContainerTitle>
            <Typhography fontStyle="bold" size="very-bigger">
              Sua senha foi alterada com sucesso!
            </Typhography>
          </ContainerTitle>

          <Button
            backgroundButton="primary"
            disabled={loading}
            type="button"
            onClick={() => navigate(-1)}
          >
            Voltar ao login
          </Button>
        </Container>
      ) : null}
    </AuthScreen>
  );
}
