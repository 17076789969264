import {
  setMicDevices,
  setVideoDevices,
  setAudioDevices,
  setPermissions,
  selectMicDevice,
  selectVideoDevice,
  selectAudioDevice,
  setMicLevel,
  setLoading,
} from "store/modules/mediaDevices/actions";
import { changeUserPreferences } from "store/modules/user/actions";
import { createAppAsyncThunk } from "store/rootReducer";

import { customerPreferencesService } from "services/customerPreferences";

import { LogOperation } from "utils/entities/logOperation";
import { formatDevices } from "utils/formatDevices";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "utils/sessionStorageEncrypt";

export const checkPermissionsThunk = createAppAsyncThunk(
  "mediaDevices/checkPermissions",
  async (_, { dispatch }) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      dispatch(
        setPermissions({
          mic: devices.some((device) => device.kind === "audioinput"),
          camera: devices.some((device) => device.kind === "videoinput"),
          audio: devices.some((device) => device.kind === "audiooutput"),
          stream:
            devices.some((device) => device.kind === "videoinput") &&
            devices.some((device) => device.kind === "audioinput"),
        }),
      );
    } catch (error) {
      console.error("Erro ao verificar permissões", error);
      dispatch(
        setPermissions({
          mic: false,
          camera: false,
          audio: false,
          stream: false,
        }),
      );
    }
  },
);

export const getDevicesThunk = createAppAsyncThunk(
  "mediaDevices/getDevices",
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

      const devices = await navigator.mediaDevices.enumerateDevices();

      const rawMic = devices.filter((d) => d.kind === "audioinput");
      const rawVideo = devices.filter((d) => d.kind === "videoinput");
      const rawAudio = devices.filter((d) => d.kind === "audiooutput");

      const mic = rawMic.map((d, i) => ({
        id: d.deviceId,
        name: d.label || `Microfone ${i + 1}`,
        label: d.label || `Microfone ${i + 1}`,
        selected: i === 0,
      }));

      const video = rawVideo.map((d, i) => ({
        id: d.deviceId,
        name: d.label || `Câmera ${i + 1}`,
        label: d.label || `Câmera ${i + 1}`,
        selected: i === 0,
      }));

      const audio = rawAudio.map((d, i) => ({
        id: d.deviceId,
        name: d.label || `Alto-falante ${i + 1}`,
        label: d.label || `Alto-falante ${i + 1}`,
        selected: i === 0,
      }));

      dispatch(setMicDevices(mic));
      dispatch(setVideoDevices(video));
      dispatch(setAudioDevices(audio));
    } catch (err) {
      console.error("Erro ao obter devices", err);
    } finally {
      dispatch(setLoading(false));
    }
  },
);

export const persistPreferencesThunk = createAppAsyncThunk(
  "mediaDevices/persistPreferences",
  async (_, { getState, dispatch, extra }) => {
    try {
      const state = getState();
      const mic = state.mediaDevices.selectedMic;
      const audio = state.mediaDevices.selectedAudio;
      const video = state.mediaDevices.selectedVideo;

      const userId = LogOperation.getUserId();
      const machineIp = LogOperation.getMachineIP();

      const micPreference = JSON.stringify(mic);
      const audioPreference = JSON.stringify(audio);
      const videoPreference = JSON.stringify(video);

      const existing = getValueFromlocalStorage("@Kenta:user_preferences");
      let parsed = existing ? JSON.parse(existing) : null;

      if (!parsed?.id) {
        const { body } = await customerPreferencesService.GetByUserId(userId);
        parsed = body || null;
      }

      if (parsed?.id) {
        const { success } = await customerPreferencesService.put({
          id: parsed.id,
          audioPreference,
          videoPreference,
          micPreference,
          deviceRegistration: machineIp,
          userId,
          createIn: parsed.createIn,
          updateIn: new Date().toISOString(),
        });

        if (!success) throw new Error("Erro ao atualizar preferências");

        dispatch(changeUserPreferences(parsed));
        insertTolocalStorage("@Kenta:user_preferences", JSON.stringify(parsed));
      } else {
        const { body } = await customerPreferencesService.add({
          audioPreference,
          videoPreference,
          micPreference,
          deviceRegistration: machineIp,
          userId,
          createIn: new Date().toISOString(),
        });

        dispatch(changeUserPreferences(body));
        insertTolocalStorage("@Kenta:user_preferences", JSON.stringify(body));
      }

      extra.addToast({
        type: "success",
        title: "Preferências salvas",
        description: "As preferências de dispositivo foram salvas com sucesso.",
      });
    } catch (err) {
      console.error("Erro ao persistir preferências", err);
    }
  },
);

export const changeMicThunk = createAppAsyncThunk(
  "mediaDevices/changeMic",
  async (deviceId: string, { dispatch, getState }) => {
    try {
      const mic = getState().mediaDevices.micDevices.find(
        (m: any) => m.id === deviceId,
      );
      if (!mic) return;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: deviceId } },
      });

      dispatch(selectMicDevice(mic));

      const audioContext = new AudioContext();
      const micSource = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      micSource.connect(analyser);
      analyser.fftSize = 256;
      const buffer = new Uint8Array(analyser.frequencyBinCount);

      const updateMicLevel = () => {
        analyser.getByteFrequencyData(buffer);
        const avg = buffer.reduce((a, b) => a + b, 0) / buffer.length;
        dispatch(setMicLevel(avg));
        requestAnimationFrame(updateMicLevel);
      };

      updateMicLevel();
    } catch (err) {
      console.error("Erro ao trocar mic", err);
    }
  },
);

export const changeVideoThunk = createAppAsyncThunk(
  "mediaDevices/changeVideo",
  async (deviceId: string, { dispatch, getState }) => {
    const video = getState().mediaDevices.videoDevices.find(
      (v: any) => v.id === deviceId,
    );
    if (video) dispatch(selectVideoDevice(video));
  },
);

export const changeAudioThunk = createAppAsyncThunk(
  "mediaDevices/changeAudio",
  async (deviceId: string, { dispatch, getState }) => {
    const audio = getState().mediaDevices.audioDevices.find(
      (a) => a.id === deviceId,
    );

    if (audio) {
      dispatch(selectAudioDevice(audio));

      if ("setSinkId" in HTMLMediaElement.prototype) {
        try {
          const testAudio = new Audio();
          if (typeof testAudio.setSinkId === "function") {
            await testAudio.setSinkId(deviceId);
          }
        } catch (err) {
          console.warn("Erro ao aplicar setSinkId", err);
        }
      }
    }
  },
);

export const restorePreferencesThunk = createAppAsyncThunk(
  "mediaDevices/restorePreferences",
  async (_, { dispatch }) => {
    try {
      const userId = LogOperation.getUserId();
      if (!userId) return;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });

      const devices = await navigator.mediaDevices.enumerateDevices();

      stream.getTracks().forEach((track) => track.stop());

      const mic = formatDevices(
        devices.filter((device) => device.kind === "audioinput"),
        "Microfone",
      );
      const video = formatDevices(
        devices.filter((device) => device.kind === "videoinput"),
        "Câmera",
      );
      const audio = formatDevices(
        devices.filter((device) => device.kind === "audiooutput"),
        "Alto-falante",
      );

      dispatch(setMicDevices(mic));
      dispatch(setVideoDevices(video));
      dispatch(setAudioDevices(audio));

      const { body } = await customerPreferencesService.GetByUserId(userId);
      if (!body) return;

      insertTolocalStorage("@Kenta:user_preferences", JSON.stringify(body));

      const micPref = body.micPreference
        ? JSON.parse(body.micPreference)
        : null;
      const audioPref = body.audioPreference
        ? JSON.parse(body.audioPreference)
        : null;
      const videoPref = body.videoPreference
        ? JSON.parse(body.videoPreference)
        : null;

      const validMic = mic.find((m) => m.id === micPref?.id) || mic[0];
      const validAudio = audio.find((a) => a.id === audioPref?.id) || audio[0];
      const validVideo = video.find((v) => v.id === videoPref?.id) || video[0];

      if (validMic) dispatch(selectMicDevice(validMic));
      if (validAudio) dispatch(selectAudioDevice(validAudio));
      if (validVideo) dispatch(selectVideoDevice(validVideo));
    } catch (error) {
      console.error("Erro ao restaurar preferências:", error);
    }
  },
);
