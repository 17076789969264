import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { ContainerItems } from "./styles";
import { CardDownloadFile } from "../../components/CardDownloadFile";
import ModalRight from "../../components/ModalRight";
import { storageKentaService } from "../../services/storageKenta";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import { LogOperation } from "../../utils/entities/logOperation";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

interface Props {
  isOpenDownloadsModal: boolean;
  setIsOpenDownloadsModal: (isOpen: boolean) => void;
}

export function DownloadModal({
  isOpenDownloadsModal,
  setIsOpenDownloadsModal,
}: Props) {
  const [listOfDownloads, setListOfDownloads] = useState([]);
  const dispatch = useDispatch();

  async function findFiles() {
    dispatch(showLoading());

    const user = getValueFromlocalStorage("@Kenta:user");
    if (!user) {
      dispatch(hideLoading());
      throw new Error("Usuário não encontrado no localStorage.");
    }

    const user_parsed = JSON.parse(user);
    const customerId = user_parsed.customerId;

    const { success, body } = await storageKentaService.GetAllBlobs(customerId);
    if (success && body.length > 0) {
      setListOfDownloads(body);
    }
    dispatch(hideLoading());
  }

  useEffect(() => {
    if (isOpenDownloadsModal) findFiles();
  }, [isOpenDownloadsModal]);

  const tamanho = (bytes: number) => {
    const total = (bytes / 1024).toFixed(2);
    if (Number(total) > 1000) {
      return (bytes / 1048576).toFixed(2) + " MB ";
    }
    return total + " KB";
  };

  const clicouEmBaixar = () => {
    LogOperation.postLogOperation("HearingFile", 6, 0);
  };

  return (
    <>
      <ModalRight
        isOpen={isOpenDownloadsModal}
        setIsOpen={() => {
          setIsOpenDownloadsModal(!isOpenDownloadsModal);
        }}
        title={"Aplicativos e documentações"}
        onClickAplicar={() => {}}
        haveFooter={false}
      >
        <ContainerItems>
          {listOfDownloads &&
            listOfDownloads.length > 0 &&
            listOfDownloads.map((item: any) => {
              return (
                <CardDownloadFile
                  tipo={4}
                  key={String(item.filename)}
                  description={tamanho(item.filesize)}
                  name={item.name}
                  onClick={() => {
                    clicouEmBaixar();
                    window.open(item.url);
                  }}
                />
              );
            })}
        </ContainerItems>
      </ModalRight>
    </>
  );
}
