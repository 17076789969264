import { userCreateProfileTypes } from "../actions/actios.types";

interface userCreateProfile {
  userCreateProfile: userCreateProfile | null;
}

const initialState: userCreateProfile = {
  userCreateProfile: null,
};

const userCreateProfileReducer = (
  state = initialState,
  action: any,
): userCreateProfile => {
  switch (action.type) {
    case userCreateProfileTypes.SET_USERCREATE_PROFILE:
      return {
        ...state,
        userCreateProfile: action.payload,
      };
    default:
      return state;
  }
};
export default userCreateProfileReducer;
