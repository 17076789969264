import { screenStatusTypes } from "../actions/actios.types";

interface userCreateProfile {
  record: boolean;
}

const initialState: userCreateProfile = {
  record: false,
};

const screenStatusReducer = (
  state = initialState,
  action: any,
): userCreateProfile => {
  switch (action.type) {
    case screenStatusTypes.SET_SCREEN_STATUS:
      return {
        ...state,
        record: action.status,
      };
    default:
      return state;
  }
};
export default screenStatusReducer;
