import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { CreateEditHearingType } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { HearingTypeService } from "../../../services/hearingType";
import { listSearch } from "../../../services/utils";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";

//Toast

//Permissões
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { stringFormat } from "../../../utils/stringFormat";

export interface HearingTypeInterface {
  active: boolean;
  customerId: number;
  id: number;
  name: string;
}

export function HearingType() {
  const [listHearingType, setHearingType] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameHearingType, setNameHearingType] = useState<string>("");
  const [element, setElement] = useState<HearingTypeInterface>();
  const [active, setActive] = useState(true);
  const user = getValueFromlocalStorage("@Kenta:user");
  const [search, setSearch] = useState("");
  const [situation, setSituation] = useState<any>(true);

  const [fieldError, setFieldError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }
  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  function onLoadList() {
    dispatch(showLoading());
    (async () => {
      await api
        .get(
          `HearingType/GetAllByCustomerId?customerId=${customerId}${
            listSearch(situation) === 1 || listSearch(situation) === 0
              ? `&Active=${listSearch(situation)}`
              : ""
          }`,
        )
        .then((response) => {
          setHearingType(response.data.data);
          dispatch(hideLoading());
        })
        .catch((error) => {
          dispatch(hideLoading());
          console.log(error);
        });
    })();
  }

  function headerTrashButtonOnClick() {
    alert("clicou headerTrashButtonOnClick");
  }

  async function postHearingType() {
    if (!nameHearingType || stringFormat.isEmptyOrSpaces(nameHearingType)) {
      addToast({
        type: "warning",
        title: "Tipo de oitiva",
        description:
          "Para criar um tipo de oitiva é necessário preencher os campos destacados",
      });
      setFieldError(true);
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listHearingType,
      nameHearingType,
    );

    // if (existName) {
    //   addToast({
    //     type: "warning",
    //     title: "Tipo de oitiva",
    //     description: "Esse nome já existe",
    //   });
    //   return;
    // }

    dispatch(showLoading());

    const resultPost = await HearingTypeService.postHearingType(
      "",
      createBody(),
    );
    if (resultPost.status) {
      addToast({
        type: "success",
        title: "Tipo de oitiva",
        description: "Tipo de oitiva criada com sucesso",
      });
      clear();
      await onLoadList();
    } else {
      if (!resultPost.status) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPost.message,
        });
      } else {
        addToast({
          type: "error",
          title: "Falha ao criar o tipo de oitiva",
        });
      }
    }

    dispatch(hideLoading());
  }

  async function putHearingType() {
    if (!nameHearingType || stringFormat.isEmptyOrSpaces(nameHearingType)) {
      addToast({
        type: "warning",
        title: "Tipo de oitiva",
        description:
          "Para atualizar um tipo de oitiva é necessário preencher todos os campos.",
      });
      setFieldError(true);
      return;
    }

    // const existName = stringFormat.verifyExistNameInArray(
    //   listHearingType,
    //   nameHearingType,
    //   element?.id
    // );

    // if (existName) {
    //   addToast({
    //     type: "warning",
    //     title: "Tipo de oitiva",
    //     description: "Esse nome já existe",
    //   });
    //   return;
    // }

    dispatch(showLoading());

    const resultPut = await HearingTypeService.putHearingType("", createBody());
    if (resultPut.status) {
      addToast({
        type: "success",
        title: "Tipo de oitiva",
        description: "Tipo de oitiva atualizada com sucesso",
      });
      clear();
      await onLoadList();
    } else {
      if (!resultPut.status) {
        addToast({
          type: "warning",
          title: "Aviso",
          description: resultPut.message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Falha ao criar o tipo de oitiva",
        });
      }
    }
    dispatch(hideLoading());
  }

  async function deleteHearingType() {
    dispatch(showLoading());
    if (!element) {
      addToast({
        type: "warning",
        title: "Tipo de oitiva não encontrado",
      });
      return;
    } else {
      dispatch(showLoading());

      setOpenModal(false);
      const resultDelete = await HearingTypeService.deleteHearingType(
        "",
        element.id,
      );
      if (resultDelete) {
        addToast({
          type: "success",
          title: "Tipo de Oitiva inativada com sucesso",
        });
        clear();
        await onLoadList();
      } else {
        addToast({
          type: "warning",
          title: "Falha ao inativar o tipo de oitiva",
        });
      }

      dispatch(hideLoading());
    }
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      name: nameHearingType,
      active: active,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  function onClickHearingType(item: HearingTypeInterface) {
    setIsEdit(true);
    setNameHearingType(item.name);
    setActive(item.active);
    setElement(item);
    setOpenModal(true);
  }

  function clear() {
    setIsEdit(false);
    setNameHearingType("");
    setElement(undefined);
    setOpenModal(false);
    setFieldError(false);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }
  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };
  const filteredData = listHearingType.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );
  const situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation,
  );

  return (
    <>
      <AdminArea
        title={"Tipos de oitivas"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={headerTrashButtonOnClick}
        onClick={(item: any) => onClickHearingType(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        permissionName={FORMS_ADMIN.HEARING_TYPE}
        onChangeSituation={(value: any) => handleSitualtion(value)}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={isEdit ? "Editando tipo de oitiva" : "Criar tipo de oitiva"}
        onClickAplicar={isEdit ? putHearingType : postHearingType}
        showIconDelete={false}
        onClickDelete={deleteHearingType}
      >
        <CreateEditHearingType
          onChangeName={setNameHearingType}
          valueEdit={nameHearingType}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          fieldError={fieldError}
        />
      </ModalRight>
    </>
  );
}
