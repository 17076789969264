import { useEffect, useState } from "react";

//Toast
import { useDispatch } from "react-redux";

//Permissões
import { useSearchParams } from "react-router-dom";

import { CreateEditProcedureCustomerSource } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";
import { ProcedureCustomerSourceService } from "../../../services/procedureCustomerSource";
import { listSearch } from "../../../services/utils";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { stringFormat } from "../../../utils/stringFormat";

export interface ProcedureCustomerSourceInterface {
  active: boolean;
  customerId: number;
  id: number;
  name: string;
}

type ISituation = true | false | undefined;

export function ProcedureCustomerSource() {
  const [listProcedureCustomerSource, setListProcedureCustomerSource] =
    useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameProcedureCustomerSource, setNameProcedureCustomerSource] =
    useState<string>("");
  const [element, setElement] = useState<ProcedureCustomerSourceInterface>();
  const [active, setActive] = useState(true);
  const user = getValueFromlocalStorage("@Kenta:user");
  const [searchParams, setSearchParams] = useSearchParams();
  const [situation, setSituation] = useState<ISituation>(true);
  const [search, setSearch] = useState("");

  const [fieldError, setFieldError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    const response =
      await ProcedureCustomerSourceService.getAllProcedureCustomerSource(
        customerId,
        listSearch(situation),
      );
    if (response) {
      if (response.status) {
        setListProcedureCustomerSource(response.data?.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
      dispatch(hideLoading());
    }
    return [];
  }

  function clear() {
    setIsEdit(false);
    setNameProcedureCustomerSource("");
    setElement(undefined);
    setOpenModal(false);
    setFieldError(false);
    setActive(true);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  function headerTrashButtonOnClick() {
    alert("clicou headerTrashButtonOnClick");
  }

  function onClickProcedureCustomerSource(
    item: ProcedureCustomerSourceInterface,
  ) {
    setIsEdit(true);
    setNameProcedureCustomerSource(item.name);
    setActive(item.active);
    setElement(item);
    setOpenModal(true);
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      name: nameProcedureCustomerSource,
      active: active,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  async function postProcedureCustomerSource() {
    if (
      !nameProcedureCustomerSource ||
      stringFormat.isEmptyOrSpaces(nameProcedureCustomerSource)
    ) {
      addToast({
        type: "warning",
        title: "Origem do procedimento",
        description:
          "Para criar a origem do procedimento é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listProcedureCustomerSource,
      nameProcedureCustomerSource,
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return;
    }

    dispatch(showLoading());

    setOpenModal(false);
    const resultPost =
      await ProcedureCustomerSourceService.postProcedureCustomerSource(
        "",
        createBody(),
      );
    if (resultPost.status) {
      addToast({
        type: "success",
        title: "Origem do procedimento",
        description: "A origem do procedimento foi criada com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      if (!resultPost.status) {
        addToast({
          type: resultPost.severity as any,
          title: "Erro",
          description: resultPost.message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Falha ao criar a origem do procedimento",
        });
      }
    }

    dispatch(hideLoading());
  }

  async function putProcedureCustomerSource() {
    if (
      !nameProcedureCustomerSource ||
      stringFormat.isEmptyOrSpaces(nameProcedureCustomerSource)
    ) {
      addToast({
        type: "warning",
        title: "Origem do procedimento",
        description:
          "Para atualizar a origem do procedimento é necessário preencher os campos destacados.",
      });
      setFieldError(true);
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listProcedureCustomerSource,
      nameProcedureCustomerSource,
      element?.id,
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return;
    }

    dispatch(showLoading());

    const resultPut =
      await ProcedureCustomerSourceService.putProcedureCustomerSource(
        "",
        createBody(),
      );
    if (resultPut.status) {
      addToast({
        type: "success",
        title: "Origem do procedimento",
        description: "A origem do procedimento foi atualizada com sucesso.",
      });

      clear();
      await onLoadList();
    } else {
      addToast({
        type: "error",
        title: "Erro",
        description: resultPut.message,
      });
    }
    dispatch(hideLoading());
  }

  async function deleteProcedureCustomerSource() {
    alert("click deleteProcedureCustomerSource");
  }

  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };
  const filteredData = listProcedureCustomerSource.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );
  const situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation,
  );

  const onChangeName = (e: string) => {
    setNameProcedureCustomerSource(e);
    setFieldError(!e.trim());
  };

  return (
    <>
      <AdminArea
        title={"Origem do procedimento"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={headerTrashButtonOnClick}
        onClick={(item: any) => onClickProcedureCustomerSource(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        onChangeSituation={(value: any) => handleSitualtion(value)}
        permissionName={FORMS_ADMIN.PROCE_CUSTO_SOURSE}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={
          isEdit
            ? "Editando origem do procedimento"
            : "Criar origem do procedimento"
        }
        onClickAplicar={
          isEdit ? putProcedureCustomerSource : postProcedureCustomerSource
        }
        showIconDelete={false}
        onClickDelete={deleteProcedureCustomerSource}
      >
        <CreateEditProcedureCustomerSource
          onChangeName={onChangeName}
          valueEdit={nameProcedureCustomerSource}
          active={active}
          onChangeSwitch={() => setActive(!active)}
          fieldError={fieldError}
        />
      </ModalRight>
    </>
  );
}
