import { AxiosError } from "axios";

import { LogOperation } from "../../utils/entities/logOperation";
import api from "../api";
import { returnResponse } from "../utils";

export const FileService = {
  upload: async (payload: any) => {
    try {
      const userId = LogOperation.getUserId();

      const { data } = await api.post(
        `/UserCustomer/UploadPhoto?userId=${userId}`,
        payload,
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
};
