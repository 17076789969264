import React, { useEffect, useRef, useState } from "react";

import { Editor } from "@tinymce/tinymce-react";

import { Container } from "./styles";
import { DropdownGlobal } from "../../../components/DropdownGlobal";
import { usePermissions } from "../../../hooks/permission";
import { useToast } from "../../../hooks/toast";
import { ICustomerTemplate } from "../../../pages/app/CustomerTemplate";
import { customerTemplateService } from "../../../services/customerTemplate";
import { HearingMinuteServices } from "../../../services/minute";
import { editorInit } from "../../../services/utils/tinyConfiguration";
import { FORM_EXPORT_FILES } from "../../../utils/entities/permission";
import { useQuery } from "../../../utils/get";
import {
  ContainerButton,
  ContainerTemplate,
  NewMarkingButton,
  SpaceButton,
} from "../styles";

interface IAta {
  template?: ICustomerTemplate;
  modelList: Array<any>;
  setSelectedModel: (item: any) => unknown;
}

export const AtaComponent: React.FC<IAta> = ({
  template,
  modelList,
  setSelectedModel,
}) => {
  const { addToast } = useToast();
  const [ata, setAta] = useState<any>([]);
  const [selectTemplate, setSelectTemplate] = useState<any>();
  const [tinyText, setTinyText] = useState("");
  const AtaRef = useRef<any>();
  const query = useQuery();

  const hearingId = query.get("hearingId");
  const permissions = usePermissions(FORM_EXPORT_FILES.HEARING_FILE);

  const getAtaList = async () => {
    const { success, body } = await HearingMinuteServices.getAllByHearingId({
      hearingId: hearingId ? hearingId : "",
    });

    if (success) setAta(body);
    if (!success) {
      addToast({
        type: "warning",
        title: "Ata da oitiva",
        description: body,
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getAtaList();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectTemplate) {
        const { body, message, success } =
          await customerTemplateService.SetKeywords({
            hearingId: Number(hearingId),
            customerTemplateId:
              selectTemplate && selectTemplate.id ? selectTemplate.id : 0,
          });

        setTinyText(body);
      }
    })();
  }, [selectTemplate, hearingId]);

  const saveAta = async () => {
    if (AtaRef.current && AtaRef.current.getContent()) {
      if (ata && ata[0] && ata[0].id) {
        const { success } = await HearingMinuteServices.update({
          id: ata[0].id,
          hearingId: hearingId ? hearingId : "",
          content: AtaRef.current.getContent(),
          customerTemplateId: ata[0]
            ? ata[0].customerTemplateId
            : selectTemplate && selectTemplate.id
              ? selectTemplate.id
              : 0,
        });

        if (
          ata &&
          ata.length &&
          ata[0].id &&
          selectTemplate &&
          selectTemplate.id
        ) {
          HearingMinuteServices.patchTemplate({
            id: ata[0].id,
            customerTemplateId: selectTemplate.id,
          });
        }

        if (success) {
          addToast({
            type: "success",
            title: "Ata da oitiva",
            description: "Ata da oitiva foi atualizada com sucesso",
          });
        }

        if (!success) {
          addToast({
            type: "warning",
            title: "Ata da oitiva",
            description: "Ata da oitiva não foi atualizada.",
          });
        }
      } else {
        const { success } = await HearingMinuteServices.add({
          hearingId: hearingId ? hearingId : "",
          content: AtaRef.current.getContent(),
          customerTemplateId:
            selectTemplate && selectTemplate.id ? selectTemplate.id : 0,
        });

        await getAtaList();
        if (success) {
          addToast({
            type: "success",
            title: "Ata da oitiva",
            description: "Ata da oitiva foi salva com sucesso",
          });
        }

        if (!success) {
          addToast({
            type: "warning",
            title: "Ata da oitiva",
            description: "Ata da oitiva não foi salva com sucesso",
          });
        }
      }
    }
  };

  const selectedModel =
    ata && ata[0] && modelList.length
      ? modelList.find((x) => x.id === ata[0].customerTemplateId)
      : { name: "" };

  useEffect(() => {
    if (ata && ata.length) {
      setTinyText(ata[0].content);
    }
  }, [ata]);

  return (
    <>
      <ContainerTemplate>
        <div style={{ paddingLeft: "20px" }}>
          <DropdownGlobal
            labelName="Modelo"
            options={modelList as any}
            defaultValue={selectedModel ? selectedModel.name : ""}
            showDefaultValue={!!selectedModel && !!selectedModel.name}
            width="320px"
            disabled={!!ata.length}
            setSelected={setSelectTemplate}
            wantAnObject
          />
        </div>
      </ContainerTemplate>
      <Container hasTranscription={true}>
        <Editor
          onKeyDown={(event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === "c") {
              event.preventDefault();
              alert("Não é possivel copiar esse texto");
            }
          }}
          apiKey="n8zc1mbomckd02imnx0kf30tjuhfosn1fcrspaf2yjlzlcpo"
          onInit={(evt, editor) => (AtaRef.current = editor)}
          init={editorInit(permissions.export)}
          initialValue={ata && ata[0] ? ata[0].content : ""}
          value={tinyText}
          onEditorChange={(e) => {
            setTinyText(e);
          }}
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
          //onPaste={(e) => e.preventDefault()}
        />
      </Container>
      <ContainerButton>
        <SpaceButton />
        <NewMarkingButton width={91} onClick={saveAta}>
          <strong>Salvar</strong>
        </NewMarkingButton>
      </ContainerButton>
    </>
  );
};
