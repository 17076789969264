//libs
import React, { useEffect, useState } from "react";

//components
import { CircleBadge } from "../Badges/Circle";

//CSS
import "./styles.css";
import { Container, ContainerInput, Label } from "./styles";

type IDatePickerKenta = {
  id?: string;
  value?: string;
  onChange: (e: string) => unknown;
  label: string;
  width?: number;
  height?: number;
  marginLeft?: number;
  maxLength?: number;
  error?: boolean;
  disabled?: boolean;
  startToday?: boolean;
  required: boolean;
  limitToday?: boolean;
  deadlineDate?: string;
};

export const DatePickerKenta: React.FC<IDatePickerKenta> = ({
  id,
  value,
  onChange,
  label,
  width,
  height,
  marginLeft,
  maxLength,
  error = false,
  disabled = false,
  startToday = false,
  required,
  limitToday = false,
  deadlineDate = "2099-12-31",
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [errorRequired, setErrorRequired] = useState<boolean>(false);
  const [deadlineDateState, setDeadlineDateState] =
    useState<string>(deadlineDate);
  const [minState, setMinState] = useState<string>("");

  useEffect(() => {
    if (value) {
      onChange(value);
    } else if (startToday) {
      onChange(new Date().toString());
    }
    getMinDate();
  }, []);

  useEffect(() => {
    if (error) {
      setErrorRequired(true);
    }
  }, [error]);

  useEffect(() => {
    if (deadlineDate !== "2099-12-31") {
      getTodayWithDeadline(deadlineDate);
      setErrorRequired(false);
    }
    getMinDate();
  }, [deadlineDate]);

  function getToday() {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    return formatDays(day, month, year);
  }

  function formatDays(day: number, month: number, year: number) {
    return year + "-" + (month < 10 ? "0" + month : month) + "-" + day;
  }

  function getTodayWithDeadline(deadlineDate: string) {
    const customerProcedureDeadline = localStorage.getItem(
      "CustomerProcedureDeadline",
    );

    if (customerProcedureDeadline && customerProcedureDeadline !== "0") {
      if (deadlineDate) {
        const format = modifyDate(
          deadlineDate,
          parseInt(customerProcedureDeadline),
        );
        setDeadlineDateState(format);
        onChange(format);
      }
    } else {
      setDeadlineDateState("2099-12-31");
    }
  }

  function modifyDate(input: string, dayModification: number): string {
    // regex that will match ####-##-## where # is a number
    const isDateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;

    // javascript will natively understand both formats when parsing a string to Date
    const date = new Date(input);
    // add the dayModification value as days to the date
    date.setDate(date.getDate() + dayModification);

    // check if it's a dateOnly string
    if (isDateOnlyRegex.test(input)) {
      // using string format to return yyyy-MM-dd format
      const month = date.getMonth() + 1;
      const day = date.getDate();
      //return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
      return `${date.getFullYear()}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    }

    // date.toJSON returns yyyy-MM-ddTHH:mm:ss.SSSZ
    // could also use date.toISOString
    return date.toJSON();
  }

  function OnChanges(event: any) {
    if (
      event === null ||
      event === undefined ||
      event === "" ||
      event.target.value === ""
    ) {
      onChange("");

      if (!startToday && !deadlineDate) {
        setMinState("");
      }
      // else {
      //   setMinState(getToday());
      // }

      if (required) {
        setErrorRequired(true);
      }
      return;
    }
    onChange(event.target.value);
    setErrorRequired(false);
  }

  function getMinDate() {
    const minDates =
      deadlineDateState !== "2099-12-31"
        ? deadlineDate
        : limitToday === true
          ? getToday()
          : "";

    setMinState(minDates);
    return minState;
  }

  return (
    <Container style={{ marginLeft: marginLeft }}>
      <Label>{label}</Label>
      {required && !disabled && <CircleBadge radius="50px" widthHeigt="8px" />}
      <ContainerInput
        width={width}
        height={height}
        notError={!errorRequired && !!value}
        error={errorRequired}
        focus={focus}
      >
        <input
          id={id}
          type="date"
          value={value}
          defaultValue={value}
          onChange={(e) => OnChanges(e)}
          width={width}
          height={height}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          min={minState}
          max={deadlineDateState}
          required={required}
          disabled={disabled}
          placeholder="dd-mm-yy"
          style={{
            height: "100%",
            width: "95%",
            border: "none",
            outline: "none",
          }}
        />
      </ContainerInput>
    </Container>
  );
};
