import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

//swagger: ProcedureCustomerTypeAttendantRole
export const procedureCustomerTypeAttendantRoleService = {
  getAllRelationsProcedureCustomerTypeAttendantRole: async (
    customerId: number,
    procedureCustomerTypeId: number,
  ) => {
    if (!customerId || !procedureCustomerTypeId) {
      return {
        status: false,
        message: "Não é possivel vincular sem o customer e o procedurecustomer",
      };
    }

    try {
      const { data } = await api.get(
        `/ProcedureCustomerTypeAttendantRole/GetHearingTypeAttendantRoleRelation`,
        {
          params: {
            customerId,
            procedureCustomerTypeID: procedureCustomerTypeId,
          },
        },
      );

      return {
        success: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  vincularProcedureTypeEAttendantRole: async (
    customerId: number,
    procedureCustomerTypeId: number,
    attendantRoleId: number,
  ) => {
    if (!customerId || !procedureCustomerTypeId || !attendantRoleId) {
      return {
        status: false,
        message: "Não é possivel vincular sem o customer e o procedurecustomer",
      };
    }

    try {
      const { data } = await api.post(
        `/ProcedureCustomerTypeAttendantRole/Add`,
        {
          customerId,
          procedureCustomerTypeId,
          attendantRoleId,
        },
      );

      return {
        success: true,
        body: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  deletarVinculoProcedureTypeEAttendantRole: async (id: number) => {
    if (!id) {
      return {
        status: false,
        message: "Não é possivel deletar",
      };
    }

    try {
      await api.delete(`/ProcedureCustomerTypeAttendantRole/Delete`, {
        params: {
          id,
        },
      });

      return {
        success: true,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data.message,
        });

        return {
          success: false,
          severity,
          status,
          body: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
