import { useEffect, useState, useCallback } from "react";

//Toast
import { useDispatch } from "react-redux";

import { CreateEditCustomerTemplate } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";

//Permissões

import { customerTemplateService } from "../../../services/customerTemplate";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { stringFormat } from "../../../utils/stringFormat";

export interface ICustomerTemplate {
  id?: number;
  customerId: number;
  type: number;
  title: string;
  description?: string;
  template: string;
  keywords: string;
  active: boolean;
  name?: string;
}

type ISituation = true | false | undefined;

export function CustomerTemplate() {
  const [listCustomerTemplate, setListCustomerTemplate] = useState<
    ICustomerTemplate[]
  >([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [template, setTemplate] = useState<string>("");
  const [id, setId] = useState<number | undefined>();
  const [keywords, setKeywords] = useState<Array<string>>([]);
  const [element, setElement] = useState<ICustomerTemplate>();
  const [actived, setActived] = useState(true);
  const [type, setType] = useState<number | undefined>();
  const user = getValueFromlocalStorage("@Kenta:user");
  const [situation, setSituation] = useState<ISituation>(true);
  const [search, setSearch] = useState("");

  const [fieldError, setFieldError] = useState<{
    type: boolean;
    title: boolean;
    description: boolean;
    template: boolean;
  }>({
    type: false,
    title: false,
    description: false,
    template: false,
  });

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const onLoadList = useCallback(async () => {
    dispatch(showLoading());
    const { success, body, message } =
      await customerTemplateService.getAllByCustomerId({
        customerId,
        active: situation === undefined ? null : situation,
        type: null,
      });

    if (success) {
      const templateList = body.map((element: ICustomerTemplate) => ({
        ...element,
        name: element?.title,
      }));

      setListCustomerTemplate(templateList);
    } else {
      addToast({
        type: "warning",
        title: message,
      });
    }
    dispatch(hideLoading());
    return [];
  }, [customerId, situation, dispatch, addToast]);

  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation, onLoadList]);

  function clear() {
    setIsEdit(false);
    setTitle("");
    setType(undefined);
    setDescription("");
    setTemplate("");
    setElement(undefined);
    setKeywords([]);
    setOpenModal(false);
    setId(undefined);
    setFieldError({
      type: false,
      template: false,
      description: false,
      title: false,
    });
    setActived(true);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  function headerTrashButtonOnClick() {
    alert("clicou headerTrashButtonOnClick");
  }

  function onClickProcedureCustomerSource({
    active,
    id,
    keywords,
    template,
    title,
    type,
    description,
  }: ICustomerTemplate) {
    setId(id);
    setIsEdit(true);
    setTitle(title);
    setDescription(description ?? "");
    setActived(active);
    setTemplate(template);
    setKeywords(keywords ? keywords?.split(";") : []);
    setType(type);
    setOpenModal(true);
  }

  function createBody(): any {
    return {
      id: id ? id : undefined,
      customerId: customerId,
      type: type,
      title: title,
      description: description,
      template: template,
      keywords: keywords ? keywords.join(";") : null,
      active: actived,
      device: LogOperation.getDevice(),
      ip: LogOperation.getMachineIP(),
      logPoliceUnitId: LogOperation.getPoliceUnitId(),
      logUserId: LogOperation.getUserId(),
    };
  }

  async function postTemplate() {
    dispatch(showLoading());
    if (!title || !type || !template || !description) {
      addToast({
        type: "warning",
        title: "Template",
        description:
          "Para criar um template é necessário preencher os campos destacados.",
      });
      dispatch(hideLoading());

      return setFieldError({
        template: !template,
        title: !title,
        description: !description,
        type: !type,
      });
    }

    if (type === 2 && !keywords.includes("Transcription")) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "É necessário adicionar a palavra chave <<Transcription>> para transcrição automática",
      });
      return dispatch(hideLoading());
    }

    const existName = stringFormat.verifyExistNameInArray(
      listCustomerTemplate,
      title,
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return dispatch(hideLoading());
    }

    dispatch(showLoading());

    setOpenModal(false);
    const { success, body, message } =
      await customerTemplateService.createTemplate(createBody() as any);
    if (success) {
      addToast({
        type: "success",
        title: "Template",
        description: "O template foi criada com sucesso.",
      });
      clear();
      await onLoadList();
    } else {
      if (!success) {
        addToast({
          type: "error",
          title: "Erro",
          description: message,
        });
      } else {
        addToast({
          type: "warning",
          title: "Falha ao atualizar o template",
        });
      }
    }

    dispatch(hideLoading());
  }

  async function putTemplate() {
    dispatch(showLoading());
    if (!title || !type || !template || !description) {
      addToast({
        type: "warning",
        title: "Origem do procedimento",
        description:
          "Para atualizar um template é necessário preencher os campos destacados.",
      });
      dispatch(hideLoading());

      return setFieldError({
        template: !template,
        title: !title,
        description: !description,
        type: !type,
      });
    }

    if (type === 2 && !keywords.includes("Transcription")) {
      addToast({
        type: "warning",
        title: "Aviso",
        description:
          "É necessário adicionar a palavra chave <<Transcription>> para transcrição automática",
      });
      return dispatch(hideLoading());
    }

    const existName = stringFormat.verifyExistNameInArray(
      listCustomerTemplate,
      title,
      id,
    );

    if (existName) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "O nome já existe na base de dados",
      });
      return dispatch(hideLoading());
    }

    dispatch(showLoading());

    const { success, message } =
      await customerTemplateService.updateTemplate(createBody());
    if (success) {
      addToast({
        type: "success",
        title: "Template",
        description: "O template foi atualizada com sucesso.",
      });

      clear();
      await onLoadList();
    } else {
      addToast({
        type: "error",
        title: "Erro",
        description: message,
      });
    }

    dispatch(hideLoading());
  }

  async function deleteProcedureCustomerSource() {
    alert("click deleteProcedureCustomerSource");
  }

  const handleSitualtion = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return null;
    }
  };

  const filteredData = listCustomerTemplate.filter((item: ICustomerTemplate) =>
    item.name?.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );

  const situationFilteredData = filteredData.filter(
    (item: ICustomerTemplate) => item.active === situation,
  );

  return (
    <>
      <AdminArea
        title={"Template"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={headerTrashButtonOnClick}
        onClick={(item: any) => onClickProcedureCustomerSource(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        onChangeSituation={(value: any) => handleSitualtion(value)}
        permissionName={FORMS_ADMIN.PROCE_CUSTO_SOURSE}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={isEdit ? "Editar template" : "Criar template"}
        onClickAplicar={isEdit ? putTemplate : postTemplate}
        showIconDelete={false}
        onClickDelete={deleteProcedureCustomerSource}
      >
        <CreateEditCustomerTemplate
          isEdit={isEdit}
          setTitle={setTitle}
          title={title}
          setDescription={setDescription}
          description={description}
          actived={actived}
          fieldError={fieldError}
          setFieldError={setFieldError}
          setTemplate={setTemplate}
          setKeywords={setKeywords}
          keywords={keywords}
          setActived={() => setActived(!actived)}
          setType={setType}
          type={type}
          template={template}
        />
      </ModalRight>
    </>
  );
}
