import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

export const PoliceUnitService = {
  getAll: async (customerId: number, status?: 0 | 1 | null) => {
    try {
      const { data } = await api.get<any>(
        `/PoliceUnit/GetAllByCustomer?customerId=${customerId}${
          status !== null ? `&Active=${status}` : ""
        }`,
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  getAllByUser: async (customerId: number, userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/PoliceUnit/GetAllByCustomer?customerId=${customerId}&userId=${userId}`,
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  GetAllByCustomerIdUserId: async (customerId: number, userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/PoliceUnit/GetAllByCustomerIdUserId?customerId=${customerId}&userId=${userId}`,
      );

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  getAllByUserProfile: async (customerId: number, userId: number) => {
    try {
      const { data } = await api.get<any>(
        `/PoliceUnit/GetAllByCustomer2?customerId=${customerId}&userId=${userId}`,
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },

  post: async (authToken: string | null, policeUnit: any) => {
    try {
      const body = {
        ...policeUnit,
      };
      const { data } = await api.post<any>(`/PoliceUnit/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      const severity = "error";
      return {
        status: false,
        message: "Error",
        data: null,
        severity,
      };
    }
  },

  put: async (authToken: string | null, policeUnit: any) => {
    try {
      const body = {
        ...policeUnit,
      };

      const { data } = await api.put<any>(`/PoliceUnit/Update`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      const severity = "error";

      return {
        status: false,
        message: "Error",
        data: null,
        severity,
      };
    }
  },

  delete: async (authToken: string | null, policeUnitId: number) => {
    try {
      const { data } = await api.delete(
        `/PoliceUnit/Delete?id=${policeUnitId}`,
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
