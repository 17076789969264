import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { CreateEditHearingTypeAttendantRole } from "./CreateEdit";
import { AdminArea } from "../../../components/Body/AdminArea";
import ModalRight from "../../../components/ModalRight";
import { useToast } from "../../../hooks/toast";
import { HearingTypeAttendantRoleService } from "../../../services/hearingTypeAttendantRole";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";

//Toast

//Permissões
import { FORMS_ADMIN } from "../../../utils/entities/permission";

export interface IDefaultObject {
  hearingTypeAttendantRoleId?: number;
  id: number;
  name: string;
  active: boolean;
}

export interface IHearingTypeAttendantRoleItem {
  hearingTypeId: number;
  hearingTypeName: string;
  active: boolean;
  attendantRoleList: Array<IDefaultObject>;
}

export interface IHearingTypeAttendantRoleResponseAPI {
  customerId: number;
  hearingType: Array<IDefaultObject>;
  attendantRole: Array<IDefaultObject>;
  hearingTypeAttendantRole: Array<IHearingTypeAttendantRoleItem>;
}

export const HearingTypeAttendantRole = () => {
  const [hearingTypeAttendantRoleObject, setHearingTypeAttendantRoleObject] =
    useState<any>();
  const [hearingTypeList, setHearingTypeList] = useState<Array<IDefaultObject>>(
    [],
  );
  const [attendantRoleList, setAttendantRoleList] = useState<
    Array<IDefaultObject>
  >([]);

  const [search, setSearch] = useState("");
  const [situation, setSituation] = useState<any>(true);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [element, setElement] = useState<any>();
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [postOrPutTrue, setPostOrPutTrue] = useState<boolean>(false);

  const filteredData = hearingTypeList.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );

  const situationFilteredData = filteredData.filter(
    (item: any) => item.active === situation,
  );

  const dispatch = useDispatch();
  const { addToast } = useToast();
  const customerId = LogOperation.getCustomerId();

  useEffect(() => {
    if (search === "") {
      onLoadList();
    }
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    const response =
      await HearingTypeAttendantRoleService.getAllByCustomerId(customerId);
    if (response) {
      if (response.status) {
        setHearingTypeAttendantRoleObject(response.data);
        setHearingTypeList(response.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    dispatch(hideLoading());
    return [];
  }
  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  function clear() {
    setIsEdit(false);
    setElement(undefined);
    setOpenModal(false);
    setFieldError(false);
  }

  const handleSituation = async (value: any) => {
    await localStorage.setItem("filter", value);
    switch (value) {
      case 1:
        return setSituation(true);
      case 2:
        return setSituation(false);
      case 3:
        return setSituation(undefined);
      default:
        return;
    }
  };

  async function onClickHearingType(item: IDefaultObject) {
    setIsEdit(true);
    const returnConsult = await transformeHearingTypeAttendantRoleObject(item);
    setElement(returnConsult);
    setOpenModal(true);
  }

  async function transformeHearingTypeAttendantRoleObject(
    hearingType: IDefaultObject,
  ) {
    const consult = await HearingTypeAttendantRoleService.getById(
      LogOperation.getCustomerId(),
      hearingType.id,
    );

    const attendantRoleList = getAttendantRoleByConsult(consult.data);
    setAttendantRoleList(attendantRoleList);

    const returnConsult: IHearingTypeAttendantRoleItem = {
      hearingTypeId: hearingType.id,
      hearingTypeName: hearingType.name,
      active: hearingType.active,
      attendantRoleList: getRelationshipHearingTypeWithAttendantRole(
        consult.data,
      ),
    };

    return returnConsult;
  }

  function getAttendantRoleByConsult(consult: Array<any>) {
    const listAttentandRole: Array<IDefaultObject> = [];
    consult.forEach((element) => {
      listAttentandRole.push({
        id: element.attendantRoleId,
        name: element.attendantRoleName,
        active: element.active,
        hearingTypeAttendantRoleId: element.id,
      });
    });

    return listAttentandRole;
  }

  function getRelationshipHearingTypeWithAttendantRole(consult: Array<any>) {
    const listHearingTypeAttentandRole: Array<IDefaultObject> = [];
    consult.forEach((element) => {
      if (element.active === "true") {
        listHearingTypeAttentandRole.push({
          id: element.attendantRoleId,
          name: element.attendantRoleName,
          active: element.active,
          hearingTypeAttendantRoleId: element.id,
        });
      }
    });

    return listHearingTypeAttentandRole;
  }

  return (
    <>
      <AdminArea
        title={"Vincular tipo participante tipo oitiva"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        situation={situation}
        list={situation === undefined ? filteredData : situationFilteredData}
        headerAddButtonOnClick={() => {}}
        headerTrashButtonOnClick={() => {}}
        onClick={(item: any) => onClickHearingType(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        permissionName={FORMS_ADMIN.HEARING_TYPE_ATTENDANT_ROLE}
        onChangeSituation={(value: any) => handleSituation(value)}
        showCreateButton={false}
        showStatus={false}
        displayAtivoFilter={false}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={true}
        title={
          isEdit
            ? "Vínculo tipo participante e tipo oitiva"
            : "Criar vinculo tipo participante tipo oitiva"
        }
        onClickAplicar={() => setPostOrPutTrue(true)}
        showIconDelete={false}
        onClickDelete={() => {}}
      >
        <CreateEditHearingTypeAttendantRole
          isEdit={isEdit}
          postOrPutTrue={postOrPutTrue}
          setPostOrPutTrue={setPostOrPutTrue}
          closeModal={openModal}
          setCloseModal={setOpenModal}
          hearingTypeList={hearingTypeList}
          attendantRoleList={attendantRoleList}
          hearingTypeAttendantRoleItem={element}
          hearingTypeAttendantRoleListToCreate={
            hearingTypeAttendantRoleObject?.hearingTypeAttendantRole
          }
        />
      </ModalRight>
    </>
  );
};
