import React, { Dispatch, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Container } from "./styles";
import Dropdown from "../../components/Dropdown";
import { InputGeneral } from "../../components/Input";
import { InputSwitch } from "../../components/InputSwitch";
import { useLoadingProfileContext } from "../../pages/app/profiles/profile-hook";
import { Drawer } from "../../pages/app/StyleCSS/bodyCreateEdit";
import { profilesService } from "../../services/profiles";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

type IProfileBodyModal = {
  active: boolean;
  onChangeSwitch: (active: boolean) => unknown;
  name?: any;
  description?: any;
  formsListData?: any;
  nameError: boolean;
  descriptionError: boolean;
  setName: (value: string) => unknown;
  setDescription: (value: string) => unknown;
  isEdit: boolean;
  userCustomerProfile?: number;
};
interface IFormAction {
  systemActionId: number;
  systemObjectUserCustomerProfileActionId: any;
  action: string;
  active: boolean;
}

interface IForm {
  userCustomerProfileId: any;
  systemObjectCustomerId: number;
  systemObjectUserCustomerProfileId: any;
  description: string;
  actionsList: IFormAction[];
}

interface IIdPair {
  optionId: string | number;
  actionId: string | number;
  systemObjectUserCustomerProfileActionId?: string | number | null;
  systemObjectUserCustomerProfileId?: string | number;
}

const ProfileBodyModal: React.FC<IProfileBodyModal> = ({
  active,
  onChangeSwitch,
  name,
  description,
  setName,
  setDescription,
  nameError,
  descriptionError,
  isEdit,
  userCustomerProfile,
}) => {
  const { userCreateProfile } = useSelector(
    (state: any) => state.userCreateProfile,
  );
  const [options, setOptions] = useState<any[]>([]);
  const [formListAction, setFormListAction] = useState<IIdPair[]>([]);
  const [formListReport, setFormListReport] = useState<IIdPair[]>([]);
  const [isOpenActions, setIsOpenActions] = useState(false);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [errorNameRequired, setErrorNameRequired] = useState(nameError);
  const [errorDescriptionRequired, setErrorDescriptionRequired] =
    useState(descriptionError);
  const { userCreateUnit } = useSelector((state: any) => state.userCreateUnit);
  const { loadingProfile } = useLoadingProfileContext();

  const authToken = getValueFromlocalStorage("@Kenta:access_Token");

  async function getData() {
    const response = await profilesService.getFormList(
      userCreateProfile?.participantItem?.id
        ? userCreateProfile?.participantItem?.id
        : 0,
    );
    const actionsList = await JSON.parse(localStorage.getItem("newList")!);

    if (response?.body?.formsList) {
      const dataFormatted: any[] = response?.body?.formsList?.map(
        (item: IForm) => {
          return {
            title: item.description,
            id: item.systemObjectCustomerId,
            userCustomerProfileId: item.userCustomerProfileId,
            systemObjectCustomerId: item.systemObjectCustomerId,
            systemObjectUserCustomerProfileId:
              item.systemObjectUserCustomerProfileId,
            actionsList: item.actionsList,
          };
        },
      );
      setOptions(dataFormatted);
      let dataProfile = {
        name: name,
        description: description,
      };
      await localStorage.setItem("dataProfile", JSON.stringify(dataProfile));

      setOptions(dataFormatted);

      dataProfile = {
        name: "",
        description: "",
      };

      localStorage.setItem("dataProfile", JSON.stringify(dataProfile));
    }
  }

  function handleKeyPress(event: any) {
    if (event.key === "-" && event.target.value === "") {
      event.preventDefault();
    }
  }

  const handleOptionClick = async (
    idPair: IIdPair,
    optionList: any[],
    setOptionList: Dispatch<any>,
  ) => {
    let optionAlreadyPresent = optionList.some(
      (optionInList) =>
        optionInList.optionId === idPair.optionId &&
        optionInList.actionId === idPair.actionId,
    );
    let newItem = false;
    let newDeleteItem = false;

    if (isEdit && authToken) {
      if (idPair.systemObjectUserCustomerProfileActionId) {
        //chamar o update
        const { status } = await profilesService.deleteAction(
          authToken,
          idPair.systemObjectUserCustomerProfileActionId,
        );
        if (status) {
          idPair.systemObjectUserCustomerProfileActionId = -1;
          newDeleteItem = true;
        }
      } else {
        if (authToken && idPair.systemObjectUserCustomerProfileId) {
          const action = {
            systemObjectUserCustomerProfileId: Number(
              idPair.systemObjectUserCustomerProfileId,
            ),
            systemActionId: Number(idPair.actionId),
          };
          //pegar a action criada
          const { status, data } =
            await profilesService.createActionByProfileRelationAction(
              authToken,
              action,
            );
          idPair.systemObjectUserCustomerProfileActionId = data;
          if (status) {
            optionAlreadyPresent = false;
            newItem = true;
          }
        } else {
          if (userCustomerProfile) {
            try {
              const { status, data } =
                await profilesService.createRelationProfileAction(
                  authToken,
                  Number(userCustomerProfile),
                  Number(idPair.optionId),
                );
              idPair.systemObjectUserCustomerProfileId = data;
              if (status && data) {
                const action = {
                  systemObjectUserCustomerProfileId: data,
                  systemActionId: Number(idPair.actionId),
                };
                const response =
                  await profilesService.createActionByProfileRelationAction(
                    authToken,
                    action,
                  );
                if (response.status && response.data) {
                  idPair.systemObjectUserCustomerProfileActionId =
                    response.data;
                  newItem = true;
                  optionAlreadyPresent = false;
                }
              }
            } catch (error) {}
          }
        }
      }
    }

    const newOptionList = optionAlreadyPresent
      ? optionList.filter(
          (optionInList) =>
            !(
              optionInList.optionId === idPair.optionId &&
              optionInList.actionId === idPair.actionId
            ),
        )
      : [...optionList, idPair];

    if (newItem || newDeleteItem) setOptionList([idPair]);
    else setOptionList(newOptionList);
  };

  useEffect(() => {
    setErrorNameRequired(nameError);
    setErrorDescriptionRequired(descriptionError);
  }, [nameError, descriptionError]);

  useEffect(() => {
    if (userCreateUnit?.errorName) {
      setErrorNameRequired(true);
    } else {
      setErrorNameRequired(false);
    }
    if (userCreateUnit?.errorEmail) {
      setErrorDescriptionRequired(true);
    } else {
      setErrorDescriptionRequired(false);
    }
  }, [userCreateUnit]);

  useEffect(() => {
    if (userCreateProfile?.participantItem) {
      setName(userCreateProfile?.participantItem?.name);
      setDescription(userCreateProfile?.participantItem?.description);
    }

    getData();
  }, []);

  useEffect(() => {
    let foiDelete = false;
    const newOptionList = options.map((optionInList) => {
      //ajustar o id do id quando null
      let systemObjectUserCustomerProfileId = null;
      if (!optionInList.systemObjectUserCustomerProfileId) {
        const resultForFormAction = formListAction.filter(
          (item) => item.optionId == optionInList.id,
        );
        const resultForFormReport = formListReport.filter(
          (item) => item.optionId == optionInList.id,
        );
        if (resultForFormAction.length > 0) {
          systemObjectUserCustomerProfileId =
            resultForFormAction[0].systemObjectUserCustomerProfileId;
        } else if (resultForFormReport.length > 0) {
          systemObjectUserCustomerProfileId =
            resultForFormReport[0].systemObjectUserCustomerProfileId;
        }
      } else {
        systemObjectUserCustomerProfileId =
          optionInList.systemObjectUserCustomerProfileId;
      }

      // formListAction

      const updatedOption = {
        ...optionInList,
        systemObjectUserCustomerProfileId,
        actionsList: optionInList.actionsList.map((actionInList: any) => {
          let isRelatorio = false;
          if (optionInList.title == "Relatórios") {
            isRelatorio = true;
          }

          const valorFormReport = () => {
            if (
              formListReport.length > 0 &&
              formListReport[0].systemObjectUserCustomerProfileActionId == -1 &&
              foiDelete === false &&
              actionInList.systemActionId === formListReport[0].actionId
            ) {
              const novaFormListReportZero = [...formListReport];
              novaFormListReportZero[0].systemObjectUserCustomerProfileActionId =
                null;
              setFormListReport([...novaFormListReportZero]);
              foiDelete = true;
              return null;
            }
            if (
              formListReport.length === 0 &&
              actionInList.systemObjectUserCustomerProfileActionId === null
            ) {
              return null;
            }

            if (
              formListReport.length === 0 &&
              actionInList.systemObjectUserCustomerProfileActionId !== null
            ) {
              return actionInList.systemObjectUserCustomerProfileActionId;
            }

            return formListReport.length > 0 &&
              actionInList.systemActionId === formListReport[0].actionId &&
              optionInList.id === formListReport[0].optionId
              ? formListReport.length > 0
                ? formListReport[0].systemObjectUserCustomerProfileActionId
                : null
              : actionInList.systemObjectUserCustomerProfileActionId;
          };

          const valorFormAction = () => {
            if (
              formListAction.length > 0 &&
              formListAction[0].systemObjectUserCustomerProfileActionId == -1 &&
              foiDelete === false &&
              actionInList.systemActionId === formListAction[0].actionId
            ) {
              const novaFormListActionZero = [...formListAction];
              novaFormListActionZero[0].systemObjectUserCustomerProfileActionId =
                null;
              setFormListAction([...novaFormListActionZero]);
              foiDelete = true;
              return null;
            }

            if (
              formListAction.length == 0 &&
              actionInList.systemObjectUserCustomerProfileActionId === null
            ) {
              return null;
            }

            if (
              formListAction.length == 0 &&
              actionInList.systemObjectUserCustomerProfileActionId !== null
            ) {
              return actionInList.systemObjectUserCustomerProfileActionId;
            }

            return formListAction.length > 0 &&
              actionInList.systemActionId === formListAction[0].actionId &&
              optionInList.id === formListAction[0].optionId
              ? formListAction.length > 0
                ? formListAction[0].systemObjectUserCustomerProfileActionId
                : null
              : actionInList.systemObjectUserCustomerProfileActionId;
          };
          let updatedAction;

          if (optionInList.systemObjectUserCustomerProfileId == null) {
            updatedAction = {
              ...actionInList,
              active: [...formListAction, ...formListReport].some(
                (itemInList: IIdPair) => {
                  if (
                    optionInList.title == "Relatórios" &&
                    itemInList.optionId === 19
                  ) {
                    isRelatorio = true;
                  }
                  const itemIsPresent =
                    itemInList.optionId === optionInList.id &&
                    itemInList.actionId === actionInList.systemActionId;
                  return itemIsPresent;
                },
              ),
            };
          } else {
            updatedAction = {
              ...actionInList,
              active: isRelatorio
                ? valorFormReport() != null
                : valorFormAction() != null,
              systemObjectUserCustomerProfileActionId: isRelatorio
                ? valorFormReport()
                : valorFormAction(),
            };
          }

          return updatedAction;
        }),
      };

      return updatedOption;
    });

    setOptions(newOptionList);
  }, [formListAction, formListReport]);

  return (
    <Container disableInteractions={loadingProfile}>
      <InputSwitch text="Ativo" checked={active} onChange={onChangeSwitch} />
      <InputGeneral
        error={errorNameRequired}
        required={true}
        label="Nome"
        value={name}
        onChange={setName}
        pattern={"[0-9]*"}
        onKeyPress={(e: any) => handleKeyPress(e)}
        startFocus={true}
        maxLength={24}
      />
      <InputGeneral
        error={errorDescriptionRequired}
        required={true}
        label="Descrição"
        value={description}
        onChange={setDescription}
        pattern={"[0-9]*"}
        onKeyPress={(e: any) => handleKeyPress(e)}
        startFocus={false}
        maxLength={45}
      />
      <Drawer />

      <Dropdown
        title="Ações"
        options={options}
        onChange={(value: IIdPair) => {
          handleOptionClick(value, formListAction, setFormListAction);
        }}
        isOpen={isOpenActions}
        setIsOpen={setIsOpenActions}
        isCreateNewProfile={!isEdit}
      />
      <Drawer style={{ marginTop: 20 }} />
      <Dropdown
        title="Relatórios"
        options={options}
        onChange={(value: IIdPair) =>
          handleOptionClick(value, formListReport, setFormListReport)
        }
        isOpen={isOpenReport}
        setIsOpen={setIsOpenReport}
        isCreateNewProfile={!isEdit}
      />
    </Container>
  );
};

export default ProfileBodyModal;
