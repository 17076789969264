import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { stringFormat } from "utils/stringFormat";

import { ContainerInputsDate } from "./style";
import { DatePickerKenta } from "../../components/DatePicker";
import { DropdownGlobal } from "../../components/DropdownGlobal";
import { InputGeneral } from "../../components/Input";
import {
  Container,
  ContainerBody,
  ContainerInputs,
} from "../../pages/app/StyleCSS/bodyCreateEdit";
import api from "../../services/api";
import { getValueFromlocalStorage } from "../../utils/sessionStorageEncrypt";

type IErrorsFields = {
  procedureNumber: boolean;
  finalDate: boolean;
  date: boolean;
  procedureType: boolean;
  procedureOrigin: boolean;
};

export type IProcedureType = {
  errorFields: IErrorsFields;
  isEdit?: boolean;
  procedureNumber?: string;
  editProcedureTypeName?: string;
  editProcedureOriginName?: string;
  itemData?: any;
  hearingList?: Array<any>;
  setProcedureNumber: (e: string) => unknown;
  setDate: (e: string | null) => unknown;
  setFinalDate: (e: string | null) => unknown;
  setProcedureTypeId: (e: number) => unknown;
  setProcedureOriginId: (e: number) => unknown;
  finalDate: any;
  date: any;
  procedureOriginId: any;
  procedureTypeId: any;
  active: boolean;
  onChangeSwitch: (e: boolean) => unknown;
};

export const ProcedureDetail: React.FC<IProcedureType> = ({
  procedureNumber = "",
  setProcedureNumber,
  editProcedureTypeName,
  editProcedureOriginName,
  setDate,
  setFinalDate,
  date,
  finalDate,
  setProcedureTypeId,
  procedureTypeId,
  setProcedureOriginId,
  procedureOriginId,
  itemData,
  errorFields,
}): JSX.Element => {
  const [procedureTypeList, setProcedureTypeList] = useState<any>([]);
  const [procedureOriginList, setProcedureOriginList] = useState([]);

  const { globalConfigurations } = useSelector(
    (state: any) => state.configurations
  );

  const inputMask = globalConfigurations.find(
    (config: any) => config.name === "ProcedureMask"
  ).value;

  const [processValueMasked, setProcessValueMasked] = useState(
    stringFormat.applyMask(procedureNumber, inputMask) || ""
  );
  const user = getValueFromlocalStorage("@Kenta:user");

  useEffect(() => {
    (async () => {
      if (user) {
        const user_parsed = JSON.parse(user);

        const { data: type } = await api.get(
          `/ProcedureCustomerType/GetAllByCustomerId?customerId=${user_parsed.customerId}`
        );

        const { data: origin } = await api.get(
          `/ProcedureCustomerSource/GetAllByCustomerId?customerId=${user_parsed.customerId}`
        );

        const typeList: any = [];
        type.data.forEach((element: any) => {
          if (element.active) typeList.push(element);
        });

        setProcedureTypeList(typeList);
        setProcedureOriginList(origin.data.filter((e: any) => e.active));
      }
    })();
  }, []);

  const onChangeProcessValue = (value: any) => {
    const unmaskedValue = value.replace(/\D/g, "");
    const maskedValue = stringFormat.applyMask(unmaskedValue, inputMask);
    setProcessValueMasked(maskedValue);
    return setProcedureNumber(unmaskedValue);
  };

  const onChangeProcessValueDefault = (value: string) => {
    const lastChar = value[value.length - 1];
    const partner = /[\d./\-']/gi;

    if (!!partner.test(lastChar) || value === "") {
      setProcedureNumber(value);
    }
  };

  return (
    <Container>
      <ContainerBody>
        <ContainerInputs>
          <InputGeneral
            error={!procedureNumber && errorFields.procedureNumber}
            value={inputMask ? processValueMasked : procedureNumber}
            onChange={
              inputMask ? onChangeProcessValue : onChangeProcessValueDefault
            }
            type="text"
            label="Número do procedimento"
            required={true}
            onKeyPress={(e) => (e.key === "Enter" ? e.preventDefault() : null)}
          />
        </ContainerInputs>
        <ContainerInputsDate>
          <DatePickerKenta
            error={errorFields.date}
            value={date}
            onChange={(e: string) => setDate(e)}
            label="Data de abertura"
            required={true}
            limitToday={true}
          />
          <DatePickerKenta
            marginLeft={12}
            error={errorFields.finalDate}
            value={finalDate}
            onChange={(e: string) => setFinalDate(e)}
            label="Prazo"
            required={true}
            limitToday={true}
            deadlineDate={date}
          />
        </ContainerInputsDate>
        <ContainerInputsDate>
          <DropdownGlobal
            error={!procedureTypeId && errorFields.procedureType}
            editDropdown={editProcedureTypeName}
            setSelected={setProcedureTypeId}
            labelName="Tipo de procedimento"
            options={procedureTypeList}
            required={true}
          />
        </ContainerInputsDate>
        <ContainerInputsDate>
          <DropdownGlobal
            error={!procedureOriginId && errorFields.procedureOrigin}
            editDropdown={editProcedureOriginName}
            setSelected={setProcedureOriginId}
            options={procedureOriginList}
            labelName="Origem"
            required={true}
          />
        </ContainerInputsDate>
      </ContainerBody>
    </Container>
  );
};
