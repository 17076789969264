import { SystemConfigurationService } from "../systemConfiguration";

interface IShortcut {
  name: string;
  value: string;
}

export const globalConfigurationsUtils = {
  setLocalstorage: async (customerId: number) => {
    const response =
      await SystemConfigurationService.getAllByCustomer(customerId);

    localStorage.setItem("globalConfiguration", JSON.stringify(response.data));

    const listShortcuts: Array<IShortcut> =
      response.data.length &&
      response.data.find((x: any) => x.name === "Shortcuts")?.value &&
      JSON.parse(response.data.find((x: any) => x.name === "Shortcuts")?.value);
    setShortcutsLoad(listShortcuts);

    // Number register by page list

    const numberPage =
      response.data.length &&
      response.data.find((x: any) => x.name === "PaginationList")?.value;

    const paginationList = numberPage !== null ? numberPage : "10";
    localStorage.setItem("PaginationList", paginationList);

    // deadline precedure
    const customerProcedureDeadlineBD =
      response.data.length &&
      response.data.find((x: any) => x.name === "CustomerProcedureDeadline")
        ?.value;

    const customerProcedureDeadline =
      customerProcedureDeadlineBD !== null ? customerProcedureDeadlineBD : "0";
    localStorage.setItem(
      "CustomerProcedureDeadline",
      customerProcedureDeadline,
    );
  },
};

function setShortcutsLoad(listShortcuts: Array<IShortcut>) {
  if (listShortcuts) {
    const playStopAux = listShortcuts.find((x) => x.name === "playStop")?.value;
    const actionBackTimeAux = listShortcuts.find(
      (x) => x.name === "actionBackTime",
    )?.value;
    const actionNextTimeAux = listShortcuts.find(
      (x) => x.name === "actionNextTime",
    )?.value;
    const timeBackNextAux = listShortcuts.find(
      (x) => x.name === "timeBackNext",
    )?.value;
    const slowSpeedAux = listShortcuts.find(
      (x) => x.name === "slowSpeed",
    )?.value;
    const fastSpeedAux = listShortcuts.find(
      (x) => x.name === "fastSpeed",
    )?.value;

    localStorage.setItem(
      "GlobalPlayStop",
      playStopAux ? playStopAux : "Espaço",
    );
    localStorage.setItem(
      "GlobalActionBackTime",
      actionBackTimeAux ? actionBackTimeAux : "ArrowLeft",
    );
    localStorage.setItem(
      "GlobalActionNextTime",
      actionNextTimeAux ? actionNextTimeAux : "ArrowRight",
    );
    localStorage.setItem(
      "GlobalTimeBackNext",
      timeBackNextAux ? timeBackNextAux : "10",
    );
    localStorage.setItem(
      "GlobalSlowSpeed",
      slowSpeedAux ? slowSpeedAux : "Ctrl+ArrowUp",
    );
    localStorage.setItem(
      "GlobalFastSpeed",
      fastSpeedAux ? fastSpeedAux : "Ctrl+ArrowDown",
    );
  } else {
    localStorage.setItem("GlobalPlayStop", "Espaço");
    localStorage.setItem("GlobalActionBackTime", "ArrowLeft");
    localStorage.setItem("GlobalActionNextTime", "ArrowRight");
    localStorage.setItem("GlobalTimeBackNext", "5");
    localStorage.setItem("GlobalSlowSpeed", "Ctrl+ArrowUp");
    localStorage.setItem("GlobalFastSpeed", "Ctrl+ArrowDown");
  }
}
