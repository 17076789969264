import { AxiosError } from "axios";
import { SHA1 as sha1 } from "crypto-js";

import { ISignIn } from "./interface";
import { insertTolocalStorage } from "../../utils/sessionStorageEncrypt";
import api from "../api";
import { HearingService } from "../hearing";
import { UserCustomerProfileService } from "../user_customer_profile";
import { returnResponse } from "../utils";

export const signIn = async ({ email, password }: ISignIn) => {
  const body: ISignIn = {
    email,
    password: sha1(password).toString().toUpperCase(),
  };

  try {
    const { data } = await api.post(
      "/UserCustomerAuthentication/CheckCredentials",
      body,
    );

    insertTolocalStorage(
      "@Kenta:access_Token",
      data.data.tokenResponse.access_Token,
    );
    insertTolocalStorage(
      "@Kenta:refresh_Token",
      data.data.tokenResponse.refresh_Token,
    );

    // recuperar a primeira unidade de policia:
    if (data.data.userCustomer) {
      const response = await UserCustomerProfileService.getAllByUserCustomer(
        data.data.tokenResponse.access_Token,
        data.data.userCustomer.id,
      );

      if (response.data && response.data.length > 0) {
        insertTolocalStorage(
          "@Kenta:unidade_selecionada",
          JSON.stringify(response.data[0]),
        );
      }
    }

    const dtNow = new Date();

    const user = {
      ...data.data.userCustomer,
      hash: data.data.userCustomerAccess.hash,
    };

    insertTolocalStorage("@Kenta:user", JSON.stringify(user));
    insertTolocalStorage("@Kenta:user", JSON.stringify(user));
    insertTolocalStorage(
      "@Kenta:password",
      sha1(password).toString().toUpperCase(),
    );

    // Tempo para refresh do token
    insertTolocalStorage(
      "@Kenta:expireIn",
      String(new Date(dtNow.getTime() + 30 * 60 * 1000)),
    );
    insertTolocalStorage(
      "@Kenta:expireIn",
      String(new Date(dtNow.getTime() + 30 * 60 * 1000)),
    );

    return {
      success: true,
      body: data,
      status: true,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { severity, status, message, data } = returnResponse({
        statusCode: error.response?.status,
        data: error?.response?.data,
        message: error?.response?.data.message,
      });

      return {
        severity,
        status,
        message: message ? message : "Error",
        data,
      };
    }

    return {
      status: false,
      message: "Error",
      data: null,
      severity: "error",
    };
  }
};

export const logout = async (hash: string) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    params: {
      hash: hash,
    },
  };
  const response = await api.delete(
    `/UserCustomerAccess/DeleteUserCustomerAccess`,
    config,
  );

  await HearingService.logoutUna();

  localStorage.clear();
  return response;
};

export const checkCredentials = async ({ email, password }: ISignIn) => {
  const body: ISignIn = {
    email,
    password: sha1(password).toString().toUpperCase(),
  };
  try {
    const { data } = await api.get(
      `/UserCustomerAccess/CheckAccessPermission?email=${email}&platform=0`,
    );

    return {
      success: true,
      body: data,
      status: true,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      const { severity, status, message, data } = returnResponse({
        statusCode: error.response?.status,
        data: error?.response?.data,
        message: error?.response?.data.message,
      });

      return {
        severity,
        status,
        message: message ? message : "Error",
        data,
      };
    }

    return {
      status: false,
      message: "Error",
      data: null,
      severity: "error",
    };
  }
};
