import { useEffect, useState } from "react";

import { Container, Title, Wrapper } from "./style";
import CheckIcon from "../../assets/svg/v.svg";
import { useLoadingProfileContext } from "../../pages/app/profiles/profile-hook";
import { SpinnerProgress } from "../Spinner";

interface IIdPair {
  optionId: string | number;
  actionId: string | number;
  systemObjectUserCustomerProfileActionId?: string | number;
  systemObjectUserCustomerProfileId?: string | number;
  active: boolean;
}

interface Props {
  systemObjectCustomerId: any;
  id?: any;
  label?: string;
  checked?: any;
  style?: any;
  systemActionId?: number;
  systemObjectUserCustomerProfileId?: any;
  name?: any;
  userCustomerProfileId?: any;
  systemObjectUserCustomerProfileActionId?: any;
  onChange: (value: IIdPair) => void;
  isCreateNewProfile: boolean;
}

export function CheckBoxProfile({
  label,
  checked,
  style,
  id,
  systemActionId,
  systemObjectUserCustomerProfileId,
  userCustomerProfileId,
  systemObjectCustomerId,
  systemObjectUserCustomerProfileActionId,
  onChange,
  isCreateNewProfile = false,
}: Props) {
  const [active, setActive] = useState(checked);
  const [itemSelected, setItemSelected] = useState({
    systemActionId,
    systemObjectUserCustomerProfileId,
    id,
  });
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [loadingCheckbox, setLoadingCheckbox] = useState(false);
  const { loadingProfile, setLoadingProfile } = useLoadingProfileContext();

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setLoadingCheckbox(true);

    onChange({
      optionId: id,
      actionId: systemActionId!,
      systemObjectUserCustomerProfileActionId:
        systemObjectUserCustomerProfileActionId,
      systemObjectUserCustomerProfileId: systemObjectUserCustomerProfileId,
      active,
    });
    setLoadingProfile(true);
    setTimeout(() => {
      setLoadingCheckbox(false);
      setLoadingProfile(false);
    }, 3000);
  }, [active]);

  function handleSelected() {
    setActive(!active);
    if (!active === false) {
      const createDeleteData = JSON.parse(
        localStorage.getItem("deleteActionProfile")!,
      );
      const deleteAll = createDeleteData ?? [];
      deleteAll.push(systemObjectUserCustomerProfileActionId);
      localStorage.setItem("deleteActionProfile", JSON.stringify(deleteAll));

      if (isCreateNewProfile) {
        const createdList = localStorage.getItem("createList");
        const storedNewList = JSON.parse(
          createdList != null ? createdList : "",
        );
        const updatedNewList = storedNewList.filter(
          (itemInList: any) =>
            !(
              itemInList?.actionsList?.[0]?.id === id &&
              itemInList?.actionsList?.[0]?.systemActionId === systemActionId
            ),
        );
        localStorage.setItem("createList", JSON.stringify(updatedNewList));
      }
    }

    if (!active && itemSelected) {
      setItemSelected((itemSelected) => ({
        ...itemSelected,
        systemActionId,
        systemObjectUserCustomerProfileId,
        id,
        systemObjectCustomerId,
      }));
      if (isCreateNewProfile) {
        const createData = JSON.parse(localStorage.getItem("createList")!);
        const newCreateData = createData ? createData : [];
        newCreateData.push({
          systemObjectCustomerId: systemObjectCustomerId,
          actionsList: [
            {
              id: id,
              systemObjectUserCustomerProfileId:
                systemObjectUserCustomerProfileId,
              systemActionId: systemActionId,
            },
          ],
        });

        localStorage.setItem("createList", JSON.stringify(newCreateData));
      }
      const updateData = JSON.parse(localStorage.getItem("newList")!);
      const newData = updateData ? updateData : [];
      newData.push({
        systemObjectUserCustomerProfileId: systemObjectUserCustomerProfileId,
        systemActionId: systemActionId,
      });
      localStorage.setItem("newList", JSON.stringify(newData));
      const newUpdateData = JSON.parse(localStorage.getItem("newUpdateList")!);
      const newUpData = newUpdateData ? newUpdateData : [];
      newUpData.push({
        userCustomerProfileId: userCustomerProfileId,
        systemObjectCustomerId: id,
      });
      localStorage.setItem("newUpdateList", JSON.stringify(newUpData));

      setItemSelected(newData);
    }
  }

  return (
    <Container style={style} disableInteractions={loadingProfile}>
      {loadingCheckbox ? (
        <SpinnerProgress />
      ) : (
        <Wrapper
          id={`option-${id}-action-${systemActionId}`}
          disableInteractions={loadingProfile}
          active={active}
          onClick={(e) => {
            handleSelected();
          }}
        >
          <img src={CheckIcon} width={12} alt="" />
        </Wrapper>
      )}

      <Title>{label}</Title>
    </Container>
  );
}
