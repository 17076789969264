import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { Container, ContainerBody, Title, ContainerList } from "./styles";
import { ButtonGlobal } from "../../../components/ButtonGlobal/ButtonGlobal";
import { InputDateGlobal } from "../../../components/InputDateGlobal";
import { ListEmpty } from "../../../components/ListEmpty";
import { QuantityComponent } from "../../../components/QuantityComponent";
import { ReactPaginateKenta } from "../../../components/ReactPaginate";
import { TitleContainer } from "../../../components/Toast/styles";
import { CardReportLogOperation } from "../../../features/CardReportLogOperation";
import { HeaderContainer } from "../../../features/HeaderHearing/styles";
import { useToast } from "../../../hooks/toast";
import { reportService } from "../../../services/report";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import { ContainerTitleWithNumber } from "../profiles/styles";

//Load

export function LogErros() {
  const today = new Date().toISOString().split("T")[0];
  const [listOfLogs, setListOfLogs] = useState([]);
  const [initialDate, setInitialDate] = useState<any>(today);
  const [endDate, setEndDate] = useState<any>(today);
  const [loading, setLoading] = useState<boolean>(false);
  const user = getValueFromlocalStorage("@Kenta:user");
  let customerId: any = null;
  const { addToast } = useToast();
  const dispatch = useDispatch();

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const inputRefNew = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRefNew.current) {
      inputRefNew.current.focus();
    }
  }, []);

  const currentPageData = listOfLogs.map((item: any, index: number) => {
    return (
      <CardReportLogOperation
        key={index}
        userName={item.description}
        operation={item.operation}
        systemObject={item.systemObject}
        dateTime={item.date}
        version={item.version}
        notPointer
      />
    );
  });

  async function searchReportLogs() {
    if (!customerId) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa de um customer id",
      });
      return;
    }
    if (!initialDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma data inicial",
      });
      return;
    }
    if (!endDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Você precisa selecionar uma data final",
      });
      return;
    }

    if (initialDate > endDate) {
      addToast({
        type: "warning",
        title: "Aviso",
        description: "Data inicial não pode ser maior que a data final",
      });
      return;
    }

    setLoading(true);
    dispatch(showLoading());
    const { status, data } = await reportService.getAllLogErros(
      customerId,
      initialDate + " 00:00",
      endDate + " 23:59",
      null,
      null,
    );

    if (status && data && data.data) setListOfLogs(data.data);
    if (status && data && !data.data) setListOfLogs([]);
    setLoading(false);
    dispatch(hideLoading());
  }

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <ContainerTitleWithNumber>
            <Title>{"Log de Erros"}</Title>
            <QuantityComponent
              value={listOfLogs.length > 0 ? listOfLogs.length : 0}
            />
          </ContainerTitleWithNumber>
        </TitleContainer>
      </HeaderContainer>
      <ContainerBody>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <div style={{ marginLeft: 20, marginTop: 20 }}>
            <InputDateGlobal
              label="Data inicial"
              required={false}
              type={"date"}
              value={initialDate}
              onChange={setInitialDate}
              disabled={false}
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <InputDateGlobal
              label="Data Final"
              required={false}
              type={"date"}
              value={endDate}
              onChange={setEndDate}
              disabled={false}
            />
          </div>

          <div style={{ marginLeft: 12, marginBottom: 28 }}>
            <ButtonGlobal
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={() => searchReportLogs()}
            >
              {" "}
              Gerar relatório
            </ButtonGlobal>
          </div>
        </div>
        <ContainerList>
          {!loading ? (
            listOfLogs && listOfLogs.length > 0 ? (
              <>
                <CardReportLogOperation
                  userName={"Descrição"}
                  dateTime={"Data"}
                  version={"Versão"}
                  notPointer
                  background
                />
                <ReactPaginateKenta
                  list={currentPageData}
                  title="Log de erros"
                  height="52vh"
                />
              </>
            ) : (
              <ListEmpty title="relatório" typeImg="file" />
            )
          ) : null}
        </ContainerList>
      </ContainerBody>
    </Container>
  );
}
