import React, { useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";

import { TranscriptionPart, Word, Channel } from "./interface";
import {
  Container,
  ContentTranscription,
  EmptyMessage,
  HighlightedWord,
  NormalWord,
  ContainerContent,
} from "./styles";
import { hearingFileService } from "../../../services/hearingFile";
import { showLoading, hideLoading } from "../../../store/modules/login/actions";
import { useQuery } from "../../../utils/get";

interface TranscriptionFollowUpProps {
  videoRef: React.RefObject<HTMLVideoElement>;
}

export const TranscriptionFollowUp: React.FC<TranscriptionFollowUpProps> = ({
  videoRef,
}) => {
  const [transcription, setTranscription] = useState<TranscriptionPart[]>([]);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [channelZeroName, setChannelZeroName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const dispatch = useDispatch();
  const query = useQuery();
  const hearingFileId = Number(query.get("hearingId"));
  const transcriptionRef = useRef<HTMLDivElement>(null);

  const fetchData = async () => {
    if (!hearingFileId) {
      return;
    }
    dispatch(showLoading());
    setLoading(true);

    try {
      const filesResponse = await hearingFileService.getAll(
        String(hearingFileId),
      );
      if (!filesResponse.success || filesResponse.body.length === 0) {
        setError("Nenhum arquivo encontrado para este hearingId");
        setLoading(false);
        return;
      }

      const firstHearingFileId = filesResponse.body[0]?.id;
      if (!firstHearingFileId) {
        setError("ID do arquivo de audição não encontrado.");
        setLoading(false);
        return;
      }

      const transcriptionResponse =
        await hearingFileService.getTranscriptionByHearingFileId(
          firstHearingFileId,
        );
      if (transcriptionResponse.success && transcriptionResponse.body) {
        setTranscription(transcriptionResponse.body.transcriptionParts);

        setChannels(transcriptionResponse.body.channels ?? []);
        setChannelZeroName(transcriptionResponse.body.item2 ?? null);
      } else {
        setError(
          transcriptionResponse.message ?? "Erro ao buscar transcrição.",
        );
      }
    } catch (err) {
      setError("Erro ao carregar transcrição.");
    }
    dispatch(hideLoading());
  };

  const prevHearingFileId = useRef<number | null>(null);

  useEffect(() => {
    if (hearingFileId !== prevHearingFileId.current) {
      prevHearingFileId.current = hearingFileId;
      fetchData();
    }
  }, [hearingFileId]);

  useEffect(() => {
    if (!videoRef?.current) return;

    const updateTime = () => setCurrentTime(videoRef.current?.currentTime || 0);
    videoRef.current.addEventListener("timeupdate", updateTime);

    return () => {
      videoRef.current?.removeEventListener("timeupdate", updateTime);
    };
  }, [videoRef]);

  useEffect(() => {
    if (!transcriptionRef.current) return;

    const highlightedWord =
      transcriptionRef.current.querySelector(".highlighted");
    if (highlightedWord) {
      const container = transcriptionRef.current;
      const wordRect = highlightedWord.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const offset = wordRect.top - containerRect.top;
      const scrollTarget =
        container.scrollTop + offset - container.clientHeight / 2;

      container.scrollTo({
        top: scrollTarget,
        behavior: "smooth",
      });
    }
  }, [currentTime]);

  const handleWordClick = (word: Word) => {
    if (videoRef.current) {
      videoRef.current.currentTime = word.begin;
      setCurrentTime(word.begin);
      if (!videoRef.current.paused) {
        videoRef.current.play();
      }
    }
  };

  return (
    <Container>
      <ContentTranscription ref={transcriptionRef}>
        {transcription.length > 0 ? (
          transcription.map((part) => {
            const speaker =
              Array.isArray(channels) && channels.length >= 2
                ? channels
                    .find((channel) => channel._id === part.channel)
                    ?.name.split("@")[0] || ""
                : "";

            return (
              <ContainerContent key={part._id}>
                {part.channel === 0 && channelZeroName && (
                  <strong>{channelZeroName} : </strong>
                )}
                {part.channel !== 0 && speaker && <strong>{speaker} : </strong>}

                {part.words.map((word: Word, index: number) => {
                  const isActive =
                    currentTime >= word.begin - 0.1 &&
                    currentTime <= word.end + 0.1;
                  return isActive ? (
                    <HighlightedWord
                      key={index}
                      onClick={() => handleWordClick(word)}
                      className="highlighted"
                    >
                      {word.word}{" "}
                    </HighlightedWord>
                  ) : (
                    <NormalWord
                      key={index}
                      onClick={() => handleWordClick(word)}
                    >
                      {word.word}{" "}
                    </NormalWord>
                  );
                })}
              </ContainerContent>
            );
          })
        ) : (
          <EmptyMessage>Nenhuma transcrição disponível.</EmptyMessage>
        )}
      </ContentTranscription>
    </Container>
  );
};
