import { IImportBody } from "./interface";
import { LogOperation } from "../../utils/entities/logOperation";
import api from "../api";

export const SispServices = {
  getAllProcedureTypeByCustomerId: async () => {
    try {
      const { data } = await api.get(
        `/ProcedureCustomerType/GetAllByCustomerId?customerId=${LogOperation.getCustomerId()}&active=1`,
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error: any) {
      return {
        success: true,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
  getAllPoliceUnitByCustomerId: async () => {
    try {
      const { data } = await api.get(
        `/PoliceUnit/GetAllByUserId?userId=${LogOperation.getUserId()}`,
      );

      return {
        success: true,
        body: data.data,
      };
    } catch (error: any) {
      return {
        success: true,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
  importBySisp: async (body: IImportBody) => {
    try {
      const { data } = await api.post("/Integrations/Import", body);

      return {
        success: true,
        body: data.data,
        message: data.message,
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
};
