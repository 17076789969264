import { AxiosError } from "axios";

import api from "../../../../services/api";
import { returnResponse } from "../../../../services/utils";

interface IVinculeAttendant {
  attendantId: number;
  extraData: string | null;
  procedureCustomerId: string;
}

interface ResponseTypeVincule {
  status: boolean;
  message: string;
  severity: string;
  data: null;
}

export const AttendandoViculeService = {
  vincule: async (body: IVinculeAttendant): Promise<ResponseTypeVincule> => {
    try {
      const { data } = await api.post("/ProcedureCustomerAttendant/add", body);

      return {
        status: true,
        message: data.data.message,
        data: data.data,
        severity: "success",
      };
    } catch (error: any) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Sucesso",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
