import api from "../api";

export const NotifyService = {
  post: async ({ hearingId }: { hearingId: number }) => {
    try {
      const { data } = await api.post(
        `/integrations/Notify?hearingId=${hearingId}`,
      );

      return {
        success: true,
        body: data.data,
        message: data.message,
      };
    } catch (error: any) {
      return {
        success: false,
        body: {},
        message: error?.response?.data?.message,
      };
    }
  },
};
