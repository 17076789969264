import React from "react";

import AppProvider from "hooks";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";

import ReduxProvider from "store/provider";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <ReduxProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </ReduxProvider>
    </CookiesProvider>
  </React.StrictMode>,
);
