import React, { useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Container, ContainerDescription, ContainerTitle } from "./style";
import { AuthScreen } from "..";
import { InputAuth } from "../../../components/Login/Input";
import { Typhography } from "../../../components/Typhography";
import { useToast } from "../../../hooks/toast";
import { PasswordService } from "../../../services/password";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { verifyEmailFormat } from "../../../utils/verifyEmailFormat";
import { Button, ForgotPasswordButton } from "../styles";

const STEPS = {
  TYPE_EMAIL: 1,
  EMAIL_VERIFY: 2,
  INFO_CREATE_PASSWORD: 3,
  CREATE_NEW_PASSWORD: 4,
  FINAL_STEP: 5,
};

export function ForgotPassword() {
  const [errorEmail, setErrorEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(STEPS.TYPE_EMAIL);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const inputRef = useRef<any>();

  async function forgotPassword() {
    if (!verifyNumberOfTries()) {
      return;
    }

    setLoading(true);
    dispatch(showLoading());
    const response = await PasswordService.forgotPassword(email);
    if (response.status) {
      setStep(STEPS.EMAIL_VERIFY);
    } else {
      if (!response.status && response.message) {
        addToast({
          type: "error",
          title: "Erro",
          description: response.message,
        });
        setExpirationTime();
      } else {
        addToast({
          type: "error",
          title: "Erro verificar o email",
          description: "Verifique se o email digitado é válido",
        });
      }
    }
    dispatch(hideLoading());
    setLoading(false);
  }

  const setExpirationTime = () => {
    const exTimeValue = localStorage.getItem("expirationTimeForgotPassword");
    if (exTimeValue) {
      // o tipo é {tries: number, time: string}
      const expirationTime = JSON.parse(exTimeValue);

      if (expirationTime.tries === 3) {
        return;
      }

      expirationTime.tries = expirationTime.tries + 1;
      const currentDate = new Date();
      expirationTime.time = new Date(currentDate.getTime() + 60 * 60 * 1000);
      localStorage.setItem(
        "expirationTimeForgotPassword",
        JSON.stringify(expirationTime),
      );
    } else {
      const expirationTime = {
        tries: 1,
        time: new Date().getTime() + 60 * 60 * 1000,
      };
      localStorage.setItem(
        "expirationTimeForgotPassword",
        JSON.stringify(expirationTime),
      );
    }
  };

  function verifyNumberOfTries() {
    const exTimeValue = localStorage.getItem("expirationTimeForgotPassword");
    if (exTimeValue) {
      const expirationTime = JSON.parse(exTimeValue);
      if (expirationTime.tries === 3) {
        const storedTime = new Date(expirationTime.time);
        const currentTime = new Date();
        const isOneHourPassed = currentTime > storedTime;

        if (!isOneHourPassed) {
          addToast({
            type: "warning",
            title: "Numero de tentativas excedidas",
            description:
              "O numero de tentativas foi excedido, tente novamente em uma hora.",
          });
          return false;
        } else {
          localStorage.removeItem("expirationTimeForgotPassword");
        }
      }
    }
    return true;
  }

  useEffect(() => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <AuthScreen>
      {/* First STEP */}
      {step === STEPS.TYPE_EMAIL ? (
        <Container>
          <ContainerTitle>
            <Typhography fontStyle="bold" size="very-bigger">
              Recuperação de senha
            </Typhography>
          </ContainerTitle>
          <ContainerDescription>
            <Typhography fontStyle="regular" size="medium">
              Informe o e-mail que você utiliza para acessar sua conta.
            </Typhography>
          </ContainerDescription>
          <InputAuth
            id="singIn_email"
            maxLength={200}
            width={380}
            error={errorEmail}
            label="Seu e-mail"
            type="email"
            disabled={loading}
            value={email}
            inputRef={inputRef}
            onChange={(email: string) => {
              setEmail(email);

              if (verifyEmailFormat(email)) {
                setErrorEmail(false);
              } else {
                setErrorEmail(true);
              }
            }}
          />
          <Button
            disabled={loading}
            type="button"
            onClick={() => {
              if (!verifyEmailFormat(email)) {
                addToast({
                  type: "error",
                  title: "Email inválido",
                  description: "Verifique se o email digitado é válido",
                });
                setErrorEmail(true);
                return;
              }
              forgotPassword();
            }}
          >
            Continuar
          </Button>
          <ForgotPasswordButton onClick={() => navigate(-1)}>
            Voltar ao login
          </ForgotPasswordButton>
        </Container>
      ) : null}
      {/* Secod step */}
      {step === STEPS.EMAIL_VERIFY ? (
        <Container style={{ height: 305 }}>
          <ContainerTitle>
            <Typhography fontStyle="bold" size="very-bigger">
              Verifique seu e-mail
            </Typhography>
          </ContainerTitle>
          <ContainerDescription>
            <Typhography fontStyle="regular" size="medium">
              Enviamos para você as instruções para a criação de uma nova senha.
            </Typhography>
          </ContainerDescription>
          <ContainerDescription>
            <Typhography fontStyle="regular" size="medium">
              Caso não tenha recebido a mensagem, repita o procedimento.
            </Typhography>
          </ContainerDescription>
          <ContainerDescription>
            <Typhography fontStyle="regular" size="medium">
              Se o problema persistir, entre contato com nosso suporte técnico.
            </Typhography>
          </ContainerDescription>
          <Button
            backgroundButton="secondary"
            disabled={loading}
            type="button"
            onClick={() => navigate(-1)}
          >
            Voltar ao login
          </Button>
        </Container>
      ) : null}
    </AuthScreen>
  );
}
