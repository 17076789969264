import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { Container, Body } from "./styles";

//Administração
import { MenuAdminDinamic } from "../../../features/MenuAdminDinamic";
import { returnPermissionsByFuncionality } from "../../../hooks/permission";
import { Attendant } from "../attendant";
import { AttendantRole } from "../attendantRole";
import { CustomerCertificate } from "../customerCertificate";
import { CustomerTemplate } from "../CustomerTemplate";
import { HearingType } from "../HearingType";
import { HearingTypeAttendantRole } from "../hearingTypeAttendantRole";
import { PoliceUnit } from "../policeUnit";
import { ProcedureCustomerSource } from "../ProcedureCustomerSource";
import { ProcedureCustomerType } from "../procedureCustomerType";

//Configurações
import { ProcedureTypeAttendantRole } from "../procedureTypeAttendantRole";
import { Profiles } from "../profiles";
import SispIntegration from "../sispIntegration";
import { SystemConfiguration } from "../systemConfiguration";
import { Users } from "../users";

export const Administration: React.FC = () => {
  const { permissions } = useSelector((state: any) => state.permissions);
  const [menus, setMenus] = useState<Array<any>>([]);

  function setMenuStorage() {
    const menuStorage = localStorage.getItem("KentaMenu");
    if (menuStorage) {
      setMenus(JSON.parse(menuStorage));
    }
  }

  useEffect(() => {
    setMenuStorage();
  }, [permissions]);

  const componentsList = [
    {
      name: "AttendantRole",
      component: <AttendantRole />,
    },
    {
      name: "HearingType",
      component: <HearingType />,
    },
    {
      name: "ProcedureCustomerSource",
      component: <ProcedureCustomerSource />,
    },
    {
      name: "Attendant",
      component: <Attendant />,
    },
    {
      name: "PoliceUnit",
      component: <PoliceUnit />,
    },
    {
      name: "UserCustomer",
      component: <Users />,
    },
    {
      name: "ProcedureCustomerType",
      component: <ProcedureCustomerType />,
    },
    {
      name: "HearingTypeAttendantRole",
      component: <HearingTypeAttendantRole />,
    },
    {
      name: "ImportSISP",
      component: <SispIntegration />,
    },
    {
      name: "ProcedureCustomerTypeAttendantRole",
      component: <ProcedureTypeAttendantRole />,
    },
    {
      name: "UserCustomerProfile",
      component: <Profiles />,
    },
    {
      name: "SystemConfiguration",
      component: <SystemConfiguration />,
    },
    {
      name: "CustomerCertificate",
      component: <CustomerCertificate />,
    },
    {
      name: "CustomerTemplate",
      component: <CustomerTemplate />,
    },
  ];

  function createRoutesDynamic() {
    return (
      <Body>
        <Routes>
          {menus.map((element) =>
            returnPermissionsByFuncionality(element.name, permissions) ? (
              <Route
                key={element.name}
                path={`/${element.name}`}
                element={
                  componentsList.find((n) => n.name === element.name)?.component
                }
              />
            ) : null,
          )}
        </Routes>
      </Body>
    );
  }

  return (
    <Container>
      <MenuAdminDinamic
        title="Administração"
        optionsShow={["Cadastros", "Configurações"]}
      />
      {createRoutesDynamic()}
    </Container>
  );
};
