import { AxiosError } from "axios";

import api from "../api";
import { returnResponse } from "../utils";

export const PasswordService = {
  forgotPassword: async (email: string) => {
    if (!email) {
      return {
        status: false,
        message: "Não é possível recuperar a senha sem o email.",
      };
    }

    try {
      const body = {
        email: email,
        platform: 0,
      };
      await api.post("/UserCustomerAuthentication/ForgotPassword", body);

      return {
        status: true,
        message: "Success",
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors?.[0].message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  changePassword: async (password: string, guid: string) => {
    if (!password) {
      return {
        status: false,
        message: "Não é possível trocar a senha, a mesma é inválida",
      };
    }
    if (!guid) {
      return {
        status: false,
        message: "Não é possível recuperar a senha, id inválido",
      };
    }

    try {
      const body = {
        guid: guid,
        password: password,
      };
      await api.post("/UserCustomerAuthentication/ChangePassword", body);
      return {
        status: true,
        message: "Success",
      };
    } catch (error: any) {
      return {
        status: false,
        message:
          error?.response?.data?.message && error?.response?.data
            ? error?.response?.data?.message
            : error?.response?.data,
        data: "",
        severity: "error",
      };
    }
  },
  resetPassword: async (password: string, id: number) => {
    if (!password) {
      return {
        status: false,
        message: "Não é possível trocar a senha, a mesma é inválida",
      };
    }

    try {
      const body = [
        {
          op: "replace",
          value: password,
          path: "/password",
        },
      ];

      await api.patch(`/UserCustomer/Update?id=${id}`, body);
      return {
        status: true,
        message: "Success",
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.code === "ERR_BAD_REQUEST" &&
          error.response?.data ===
            "A senha informada já foi utilizada anteriormente, utilize outra senha."
        ) {
          return {
            status: false,
            message: error?.response?.data?.message,
            data: "Same password",
            severity: "error",
          };
        }

        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  resetPassword2: async (
    userId: number,
    oldPassword: string,
    newPassword: string,
  ) => {
    try {
      // await api.post(
      //   `/UserCustomer/ChangePassword?userId=${userId}&oldPassword=${oldPassword}&newPassword=${newPassword}`
      // );

      const body = {
        userId: userId,
        passwordOld: oldPassword,
        passwordNew: newPassword,
      };

      await api.post(`/UserCustomer/ChangePasswor`, body);
      return {
        status: true,
        message: "Success",
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.code === "ERR_BAD_REQUEST" &&
          error.response?.data ===
            "A senha informada já foi utilizada anteriormente, utilize outra senha."
        ) {
          return {
            status: false,
            message: error?.response?.data?.message,
            data: "Same password",
            severity: "warning",
          };
        }
        if (
          error.code === "ERR_BAD_REQUEST" &&
          error.response?.data ===
            "Para continuar insira a senha atual certa e com a regra determinada acima"
        ) {
          return {
            status: false,
            message:
              "Para continuar insira a senha atual certa e com a regra determinada acima",
            data: "diferent password",
            severity: "warning",
          };
        }

        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
  resetPasswordBody: async (body: any) => {
    try {
      // const responseCreate = await api.post<any>(
      //   ` api/UserCustomerAuthentication/generatePasswordChangeRequest`
      // );

      const { data } = await api.put<any>(`/UserCustomer/Update`, body);

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.code === "ERR_BAD_REQUEST" &&
          error.response?.data ===
            "A senha informada já foi utilizada anteriormente, utilize outra senha."
        ) {
          return {
            status: false,
            message: error?.response?.data?.message,
            data: "Same password",
            severity: "warning",
          };
        }
        if (
          error.code === "ERR_BAD_RESPONSE" &&
          error.response?.data?.message ===
            "Falha na atualização do(s) registro(s). A senha informada já foi utilizada anteriormente, utilize outra senha."
        ) {
          return {
            status: false,
            message:
              "Falha na atualização do(s) registro(s). A senha informada já foi utilizada anteriormente, utilize outra senha.",
            data: "diferent password",
            severity: "warning",
          };
        }

        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  },
};
