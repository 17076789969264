import { useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useSelector } from "react-redux";

import { CheckBoxGlobal } from "../../../../components/CheckBoxGlobal";
import { DropdownUnit } from "../../../../components/DropdownUnit";
import { InputDateUserUnit } from "../../../../components/InputDateUserUnit";
import { InputSwitch } from "../../../../components/InputSwitch";
import { RadiusGlobal } from "../../../../components/RadiusGlobal";
import { PoliceUnitService } from "../../../../services/policeUnit";
import { profilesService } from "../../../../services/profiles";
import { returnResponse } from "../../../../services/utils";
import {
  Container,
  ContainerBody,
  ContainerInputs,
  ContainerSwitch,
  Drawer,
  ListProfileContainer,
  Title,
} from "../../StyleCSS/bodyCreateEdit";

interface UserCreateEdit {
  isEdit: boolean;
  name: string;
  onChangeName: (e: any) => unknown;
  email: string;
  onChangeEmail: (e: any) => unknown;
  extraContent: any;
  onChangeExtraContent: (e: any) => unknown;
  active: boolean;
  dropdownError?: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  latLng: string;
  onChangeLatLng: (e: any) => unknown;
  fieldError: boolean;
  setUnitId: (value: any) => void;
  setProfileId: (value: any) => unknown;
  currentSelectedType?: any;
  setProfileLabel: (value: any) => void;
  setUnitLabel: (value: any) => void;
  setNewData?: (value: any) => unknown;
  isEditCreatedUnit: boolean;
  unitNameError?: boolean;
  customerId?: any;
  profileId?: any;
  profileLabel?: any;
  newDate?: any;
  setExpireIn: (expireIn: string | null) => unknown;
  expireIn: string | null;
  unitId?: any;
  userId?: any;
  setIndex: (index: number | null) => unknown;
}

export const CreateEditUnit = (props: UserCreateEdit): JSX.Element => {
  const [newData, setNewData] = useState<any[]>([]);
  const [listProfiles, setListProfiles] = useState<any[]>([]);
  const [checked, setChecked] = useState(true);
  const [newDataUnit, setNewDataUnit] = useState<any>();
  const [selectedType, setSelectedType] = useState<number>(
    props.currentSelectedType ?? 2,
  );

  const { userCreateUnit, isEdit } = useSelector(
    (state: any) => state.userCreateUnit,
  );

  async function getDataUnit() {
    const dataUnitEdit = await JSON.parse(localStorage.getItem("editUnit")!);
    if (props.isEditCreatedUnit) setNewDataUnit(dataUnitEdit);

    const { data } = await PoliceUnitService.getAllByUser(
      props.customerId,
      props.userId!,
    );

    if (
      data.data &&
      data.data.length &&
      userCreateUnit &&
      userCreateUnit.userData &&
      userCreateUnit.userData.length
    ) {
      const listData: any[] = [] as any;
      for (let indexMain = 0; indexMain < data.data.length; indexMain++) {
        let exists = false;
        const elementMain = data.data[indexMain];
        for (let index = 0; index < userCreateUnit.userData.length; index++) {
          const element = userCreateUnit.userData[index];

          if (elementMain.name === element.policeUnitLabel) {
            if (
              newDataUnit &&
              newDataUnit?.policeUnit !== element.policeUnitLabel
            ) {
              exists = true;
            }

            if (
              newDataUnit &&
              newDataUnit?.policeUnit === element.policeUnitLabel
            ) {
              exists = false;
            }

            if (
              props.isEditCreatedUnit &&
              newDataUnit &&
              newDataUnit?.policeUnit === element.policeUnitLabel
            ) {
              props.setIndex(index);
              props.setProfileLabel(element.policeUnitLabel);
              props.setExpireIn(element.expireIn);
            }

            if (!props.isEditCreatedUnit) {
              exists = true;
            }
          }
        }

        if (!exists) {
          listData.push(elementMain);
        }
      }

      setNewData(listData);
    } else {
      setNewData(data.data);
    }
  }

  const getProfiles = async () => {
    try {
      const { body, status } =
        await profilesService.getAllByCustomerId2(selectedType);

      if (status) {
        setListProfiles(body);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const { severity, status, message, data } = returnResponse({
          statusCode: error.response?.status,
          data: error?.response?.data,
          message: error?.response?.data?.errors[0]?.message,
        });

        return {
          severity,
          status,
          message: message ? message : "Error",
          data,
        };
      }

      return {
        status: false,
        message: "Error",
        data: null,
        severity: "error",
      };
    }
  };

  useEffect(() => {
    if (newDataUnit) {
      props.setUnitId(newDataUnit?.unitId);
      props.setProfileId(newDataUnit?.userCustomerProfileId);

      if (
        newDataUnit &&
        newDataUnit?.policeUnit &&
        !listProfiles.length &&
        newData.length
      ) {
        newData.forEach((item) => {
          if (item.name === newDataUnit?.policeUnit) {
            setSelectedType(item.type);
          }
        }, []);
      }
    }
  }, [newDataUnit, newData]);

  useEffect(() => {
    if (checked) {
      props.setExpireIn(null);
    }
  }, [checked]);

  useEffect(() => {
    getProfiles();
    getDataUnit();
  }, [selectedType]);

  return (
    <Container>
      <ContainerBody>
        <ContainerSwitch>
          <InputSwitch
            text="Ativo"
            checked={props.active}
            onChange={() => props.onChangeSwitch(!props.active)}
          />
        </ContainerSwitch>

        <DropdownUnit
          labelName="Nome"
          options={newData}
          setSelected={(value: any) => {
            setSelectedType(value.type);
            props.setUnitLabel(value.name);
            props.unitId.current = value?.id;
          }}
          defaultValue={newDataUnit?.policeUnit}
          required
          editDropdown={"Selecione"}
          error={!props.unitId.current && props.dropdownError}
        />
        <ContainerInputs>
          <InputDateUserUnit
            label="Acesso válido até"
            required
            onChange={(value: any) => props.newDate(value)}
            disabled={checked}
          />
          <CheckBoxGlobal
            id={1}
            label="indeterminado"
            checked={checked}
            onChecked={() => setChecked(!checked)}
            style={{ marginLeft: 24 }}
          />
        </ContainerInputs>
        <Drawer />
        <ContainerInputs>
          <Title>Perfil</Title>
        </ContainerInputs>
        <ListProfileContainer>
          {listProfiles?.map((item: any, index: any) => {
            return (
              <RadiusGlobal
                key={item.id}
                item={item}
                label={item?.name}
                selectProfileId={props.profileId}
                isAllItem={true}
                onSelectProfileId={(item: any) => {
                  props.setProfileLabel(item?.name);
                  props.setProfileId(item?.id);
                }}
              />
            );
          })}
        </ListProfileContainer>
      </ContainerBody>
    </Container>
  );
};
