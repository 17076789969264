import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import useWebsocket from "react-use-websocket";

import SkeletonMaster from "./skeletonMaster";
import {
  Container,
  ContainerCharts,
  ContainerLineOne,
  ContainerLineTwo,
} from "./styles";
import { AccessByPlatform } from "../../../components/Charts/AccessByPlatform";
import { MinutesCertificate } from "../../../components/Charts/MinutesCertificate";
import { Operations } from "../../../components/Charts/Operations";
import { ProcessInQueue } from "../../../components/Charts/ProcessInQueue";
import { UsersLoggedIn } from "../../../components/Charts/UsersLoggedIn";
import { UsersSituationChart } from "../../../components/Charts/UsersSituationChart";
import { CustomerService } from "../../../services/customer";
import { DashboardService } from "../../../services/dashboard";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { LogOperation } from "../../../utils/entities/logOperation";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";
import {
  HeaderContainer,
  Title,
  TitleContainer,
} from "../../HeaderHearing/styles";

interface ChartProperties {
  chart: string;
  description: string;
  total: number;
}

interface C1 {
  ativo: number;
  inativo: number;
}

interface C2 {
  web: number;
  app: number;
  offline: number;
}

interface C3 {
  usersLoggedIn: number;
}

interface C6 {
  chart: string;
  description: string;
  total: number;
}

interface C7 {
  chart: string;
  description: string;
  total: number;
}

interface C8 {
  description: string;
  numberOfTotalAcess: number;
}

interface ChartMinutes {
  creditAmount: number;
  creditUsed: number;
}

export function DashboardMaster() {
  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const authToken = getValueFromlocalStorage("@Kenta:access_Token");
  const [charts, setCharts] = useState<Array<ChartProperties>>([]);
  const [c1, setC1] = useState<C1 | null>(null);
  const [c2, setC2] = useState<C2 | null>(null);
  const [c3, setC3] = useState<C3 | null>(null);
  const [c6, setC6] = useState<C6[] | null>(null);
  const [c7, setC7] = useState<C7 | null>(null);
  const [chartMinutes, setChartMinutes] = useState<ChartMinutes | null>(null);
  const [c8, setC8] = useState<C8 | null>(null);
  const [loading, setLoading] = useState(true);
  const user = getValueFromlocalStorage("@Kenta:user");
  const dispatch = useDispatch();
  const [screenHeight, setScreenHeight] = useState(window.screen.height);
  const [dataDashboardMaster, setDataDashboardMaster] = useState(null);
  const WS_URL_DASHBOARDMASTER = `${process.env.REACT_APP_WSS_BASE_URL}/dashboardmaster`;
  const { lastMessage, sendMessage } = useWebsocket(WS_URL_DASHBOARDMASTER, {
    onMessage: (event: MessageEvent<any>) => {
      setDataDashboardMaster(event.data);
    },
    onOpen: () => {}, //console.log("WEBSOCKET OPEN - DASHBOARD"),
    onClose: () => {}, //console.log("WEBSOCKET CLOSED - DASHBOARD"),
    shouldReconnect: () => true,
    reconnectInterval: 1000,
  });

  async function searchForAllUsers() {
    setLoading(true);
    dispatch(showLoading());
    if (authToken && policeUnit) {
      //const response = await DashboardService.getAllForDashboardMaster(authToken, policeUnit.customerId)

      await searchTranscriptionTimeUsed();
    }
    setLoading(false);
    dispatch(hideLoading());
  }

  async function searchTranscriptionTimeUsed() {
    setLoading(true);
    dispatch(showLoading());
    if (user) {
      const userJsonParsed = JSON.parse(user);
      const { data } = await CustomerService.getCustomerById(
        userJsonParsed.customerId,
      );
      if (authToken && policeUnit && userJsonParsed && data && data.cnpj) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const day = String(currentDate.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        const response = await DashboardService.getUsedTranscriptionAmount(
          data.cnpj,
          formattedDate,
        );

        setChartMinutes(response.data.data);
      }
    }
    setLoading(false);
    dispatch(hideLoading());
  }

  /**
   * get all c1 charts - situação dos usuarios
   */
  function getAllC1(): C1 | null {
    if (charts.length > 0) {
      return charts.reduce(
        (acc, cur) => {
          if (cur.chart === "c1") {
            if (cur.description === "ativo") {
              acc.ativo = cur.total;
            } else if (cur.description === "inativo") {
              acc.inativo = cur.total;
            }
          }
          return acc;
        },
        { ativo: 0, inativo: 0 },
      );
    }
    return null;
  }

  /**
   * get all c2 charts - situação dos usuarios
   */
  function getAllC2(): C2 | null {
    if (charts.length > 0) {
      return charts.reduce(
        (acc, cur) => {
          if (cur.chart === "c2") {
            if (cur.description === "web") {
              acc.web = cur.total;
            } else if (cur.description === "mobile") {
              acc.app = cur.total;
            } else if (cur.description === "desktop") {
              acc.offline = cur.total;
            }
          }
          return acc;
        },
        { web: 0, app: 0, offline: 0 },
      );
    }
    return null;
  }

  /**
   * get all c3 charts - situação dos usuarios
   */
  function getAllC3(): C3 | null {
    if (charts.length > 0) {
      return charts.reduce(
        (acc, cur) => {
          if (cur.chart === "c3") {
            if (cur.description === "logados") {
              acc.usersLoggedIn = cur.total;
            }
          }
          return acc;
        },
        { usersLoggedIn: 0 },
      );
    }
    return null;
  }

  /**
   * get all c3 charts - numero de acessos.
   */
  function getAllC8(): C8 | null {
    if (charts.length > 0) {
      return charts.reduce(
        (acc, cur) => {
          if (cur.chart === "c8") {
            if (cur.description === "controle de acesso simultâneo") {
              acc.description = cur.description;
              acc.numberOfTotalAcess = cur.total;
            }
            if (cur.description === "controle de cadastro ativo") {
              acc.description = cur.description;
              acc.numberOfTotalAcess = cur.total;
            }
          }
          return acc;
        },
        { numberOfTotalAcess: 0, description: "" },
      );
    }
    return null;
  }

  /**
   * get all c6 charts - processos em andamento
   */
  function getAllC6(): C6[] | null {
    if (charts.length > 0) {
      const c6Array = [];
      for (const item of charts) {
        if (item.chart === "c6") {
          c6Array.push(item);
        } else {
          continue;
        }
      }
      if (c6Array.length > 0) {
        return c6Array;
      }
    }
    return [];
  }

  /**
   * get all c6 charts - processos em andamento
   */
  function getAllC7(): C7 | null {
    if (charts.length > 0) {
      return charts.reduce(
        (acc, cur) => {
          if (cur.chart === "c7") {
            acc.total = cur.total;
            acc.description = cur.description;
            acc.chart = cur.chart;
          }
          return acc;
        },
        { total: 0, description: "", chart: "" },
      );
    }
    return null;
  }

  /**
   * get all c3 charts - situação dos usuarios
   */
  function getAllCOperationsAnd(): any {
    const chartC4 = [];
    const chartC5 = [];

    for (const item of charts) {
      if (item.chart === "c4") {
        chartC4.push({
          month: item.description,
          total: item.total,
        });
      }
      if (item.chart === "c5") {
        chartC5.push({
          month: item.description,
          total: item.total,
        });
      }
    }

    // Check which chart is longer
    const maxLength = Math.max(chartC4.length, chartC5.length);

    // Ensure both charts have the same number of months
    while (chartC4.length < maxLength) {
      chartC4.push({
        month: chartC5[chartC4.length].month,
        total: 0,
      });
    }

    while (chartC5.length < maxLength) {
      chartC5.push({
        month: chartC4[chartC5.length].month,
        total: 0,
      });
    }

    return {
      c5: chartC5,
      c4: chartC4,
    };
  }

  useEffect(() => {
    if (charts.length > 0) {
      setC1(getAllC1());
      setC2(getAllC2());
      setC3(getAllC3());
      setC6(getAllC6());
      setC7(getAllC7());
      setC8(getAllC8());
    }
  }, [charts]);

  useEffect(() => {
    if (dataDashboardMaster != -1 && dataDashboardMaster) {
      const dataConverted = JSON.parse(dataDashboardMaster);

      if (dataConverted.data) {
        setCharts(dataConverted.data);
        setLoading(false);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    searchForAllUsers();

    // Function to update screen resolution on window resize
    const updateScreenResolution = () => {
      setScreenHeight(window.screen.height);
    };

    // Add event listener for window resize
    window.addEventListener("resize", updateScreenResolution);

    const customerIdUser = String(LogOperation.getCustomerId());

    if (customerIdUser && customerIdUser != "") {
      sendMessage(String(LogOperation.getCustomerId()));
      sendMessage(String(LogOperation.getCustomerId()));
    }
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenResolution);
    };
  }, []);

  return (
    <Container
      style={{
        height: screenHeight,
      }}
    >
      <HeaderContainer
        style={{ width: "100%", marginBottom: 4, marginLeft: -3 }}
      >
        <TitleContainer style={{ marginLeft: 0 }}>
          <Title>Bem vindo</Title>
        </TitleContainer>
      </HeaderContainer>
      {loading || !chartMinutes ? (
        <div
          style={{
            width: "100%",
            height: "76%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <SkeletonMaster />
        </div>
      ) : (
        <>
          {charts.length === 0 ? null : ( // <></>
            <>
              <ContainerCharts
                style={{
                  height: screenHeight - 300,
                }}
              >
                <ContainerLineOne>
                  {getAllCOperationsAnd().c4 && getAllCOperationsAnd().c5 ? (
                    <Operations
                      operacoes={getAllCOperationsAnd().c5}
                      ocorrencias={getAllCOperationsAnd().c4}
                    />
                  ) : null}

                  {chartMinutes && (
                    <MinutesCertificate
                      chartMinutes={chartMinutes}
                      certificates={{
                        usersLoggedIn: c7?.total,
                        totalAccess: c8?.numberOfTotalAcess,
                        description: c8?.description
                          ? c8?.description?.charAt(0).toUpperCase() +
                            c8?.description?.slice(1)
                          : "",
                        usersActive: c3?.usersLoggedIn,
                      }}
                    />
                  )}
                </ContainerLineOne>
                <ContainerLineTwo>
                  {c2 ? (
                    <AccessByPlatform
                      app={c2.app}
                      web={c2.web}
                      offline={c2.offline}
                    />
                  ) : null}
                  {c3 ? <UsersLoggedIn total={c3.usersLoggedIn} /> : null}
                  {c1 ? (
                    <UsersSituationChart
                      active={c1.ativo}
                      inactive={c1.inativo}
                    />
                  ) : null}
                  {c6 && c6.length >= 0 && (
                    <ProcessInQueue process={c6}></ProcessInQueue>
                  )}
                </ContainerLineTwo>
              </ContainerCharts>
            </>
          )}
        </>
      )}
    </Container>
  );
}
