import React, { useEffect, useState } from "react";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  ContainerProgressBar,
  FileTitle,
  ConcludedIcon,
} from "./styles";
import concluded from "../../assets/upload_completed.svg";
import { stylesGlobalDefault } from "../../global/globalStyle";
import { useToast } from "../../hooks/toast";
import api from "../../services/api";
import { hearingFileService } from "../../services/hearingFile";
import {
  addUploadedFile,
  setAttachmentList,
} from "../../store/modules/attachment/actions";
import { IUpload } from "../../store/modules/attachment/interface";
interface IUploadFileItem {
  item: IUpload;
}

const UploadFileItem: React.FC<IUploadFileItem> = ({ item }) => {
  const [percentage, setPercentage] = useState(0);
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { uploadList, uploadedList } = useSelector(
    (state: any) => state.attachment,
  );

  useEffect(() => {
    (async () => {
      try {
        await api.post(
          `/hearingfile/uploadFile?container=${item.containerName}`,
          item.file,
          {
            onUploadProgress(progressEvent) {
              if (progressEvent && progressEvent.total) {
                const progress: number = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                );

                setPercentage(progress);
              }
            },
          },
        );
      } catch (error) {}
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (percentage === 100) {
        let canAdd = true;

        uploadedList.forEach((element: IUpload) => {
          if (element.payload.filename === item.payload.filename) {
            canAdd = false;
          }
        });

        if (canAdd) {
          await hearingFileService.add(item.payload, item.hearingId);
        }

        const { body, success } = await hearingFileService.getAll(
          item.hearingId,
        );

        const list: any = [];

        body.forEach((element: any) => {
          if (element.type !== 1) {
            list.push(element);
          }
        });
        dispatch(setAttachmentList(list));
        dispatch(addUploadedFile(item));

        addToast({
          type: "success",
          title: "Aviso",
          description: "Seu arquivo foi enviado com sucesso",
        });
      }
    })();
  }, [percentage]);

  return (
    <Container>
      <FileTitle>{item.payload.title}</FileTitle>
      <ContainerProgressBar>
        {percentage >= 100 ? (
          <ConcludedIcon src={concluded} alt="" />
        ) : (
          <CircularProgressbar
            value={percentage}
            strokeWidth={15}
            background
            backgroundPadding={10}
            styles={buildStyles({
              backgroundColor: stylesGlobalDefault.blue,
              textColor: stylesGlobalDefault.white,
              pathColor: stylesGlobalDefault.white,
              trailColor: "transparent",
              strokeLinecap: "butt",
            })}
          />
        )}
      </ContainerProgressBar>
    </Container>
  );
};

export default UploadFileItem;
