import { Fragment, useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Container,
  ContainerMenu,
  DateContainer,
  HeadeContainer,
  MenuItem,
  Title,
} from "./style";
import { TitleSidebar } from "../../components/TitleSidebar";
import {
  returnPermissionsByFuncionality,
  returnReportPermisison,
} from "../../hooks/permission";
import {
  systemObject,
  systemObjectsSubMenus,
} from "../../services/systemObject";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import { listOperation } from "../../utils/listsOperations";

export function MenuAdminDinamic(props: {
  title: string;
  optionsShow: Array<string>;
  type?: "admin" | "report";
}) {
  const [menuListItems, setMenuListItems] = useState<
    Array<systemObjectsSubMenus>
  >([]);
  const [menuSelected, setMenuSelected] = useState<number[]>([]);

  const [subMenuIdSelected, setSubMenuIdSelected] = useState<null | number>(
    null,
  );

  const { permissions } = useSelector((state: any) => state.permissions);
  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [load, setLoad] = useState<boolean>(false);

  function getMenuStorage() {
    const menuStorage = localStorage.getItem("KentaMenu");
    if (menuStorage) {
      return JSON.parse(menuStorage);
    }
  }

  useEffect(() => {
    setLoad(true);
    const menuStorage = getMenuStorage();
    const menuMaked = makeMenu(menuStorage);
    setMenuListItems(makeMenu(menuStorage) ?? []);
    setFirstItemWithPermission(menuMaked ?? []);

    setLoad(false);
  }, [permissions, policeUnit]);

  function makeMenu(menus: any) {
    const menu: Array<systemObjectsSubMenus> = [];
    if (menus) {
      const orderByMenu = listOperation.groupBy(
        menus,
        (p: systemObject) => p.url,
      );
      const keys = Object.keys(orderByMenu);

      for (let i = 0; i < keys.length; ++i) {
        const key = keys[i];

        const urlSplit = key.split("/");

        if (props.optionsShow.includes(urlSplit[3])) {
          const titleMenu: systemObjectsSubMenus = {
            id: i + 1,
            hasLineBottom: false,
            title: urlSplit[3],
            list: [],
          };

          orderByMenu[key].forEach((element: systemObject) => {
            const permissionsItems = returnPermissionsByFuncionality(
              element.name,
              permissions,
            );

            const report = returnReportPermisison({
              name: element.name,
              permissions,
            });

            if (permissionsItems.read || report.read) {
              titleMenu.list.push({
                active: element.active,
                description: element.description,
                name: element.name,
                id: element.id,
                navigate: `/${urlSplit[1]}/${urlSplit[2]}/${element.name}`,
                url: element.url,
                permissions:
                  props.type === "report" ? report : permissionsItems,
              });
            }
          });
          if (titleMenu.list.length) {
            menu.push(titleMenu);
          }
        }
      }

      return menu;
    }
  }

  function setFirstItemWithPermission(
    menuListItems: Array<systemObjectsSubMenus>,
  ) {
    if (menuListItems && menuListItems.length > 0) {
      for (let index = 0; index < menuListItems.length; index++) {
        const i = menuListItems.indexOf(menuListItems[index]);
        for (
          let indexSubMenu = 0;
          indexSubMenu < menuListItems[index].list.length;
          indexSubMenu++
        ) {
          if (menuListItems[index].list[indexSubMenu].permissions.read) {
            setMenuSelected([i]);

            const firstWithPermissionRead =
              menuListItems[index].list[indexSubMenu];
            setSubMenuIdSelected(firstWithPermissionRead.id);

            return;
          }
        }
      }
    }
  }

  return (
    <>
      <Container>
        <HeadeContainer>
          <Title
            style={{
              paddingLeft: 20,
            }}
          >
            {props.title}
          </Title>
        </HeadeContainer>
        {!load
          ? menuListItems.map((itemMenu, indexMenu) =>
              props.optionsShow.includes(itemMenu.title) &&
              itemMenu.list.length > 0 ? (
                <ContainerMenu key={itemMenu.id}>
                  <TitleSidebar
                    hasLineTop={
                      itemMenu.title === "Configurações" &&
                      menuListItems.length > 1
                        ? true
                        : false
                    }
                    titleSize={16}
                    title={itemMenu.title}
                    onShow={() => {
                      setMenuSelected((prev) =>
                        prev.includes(indexMenu)
                          ? prev.filter((item) => item !== indexMenu)
                          : [...prev, indexMenu],
                      );
                    }}
                    show={menuSelected.includes(indexMenu)}
                  />
                  {menuSelected.includes(indexMenu) ? (
                    <DateContainer>
                      {itemMenu.list.map(
                        (itemSubMenu: systemObject, indexSubMenu: number) =>
                          itemSubMenu.permissions.read === true ? (
                            <MenuItem
                              key={itemSubMenu.id}
                              selected={
                                location.pathname.toLowerCase() ===
                                itemSubMenu.navigate.toLowerCase()
                              }
                              onClick={() => {
                                setSubMenuIdSelected(itemSubMenu.id);
                                dispatch(showLoading());
                                navigate(itemSubMenu.navigate);

                                setTimeout(() => {
                                  dispatch(hideLoading());
                                }, 300);
                              }}
                            >
                              {itemSubMenu.description}
                            </MenuItem>
                          ) : null,
                      )}
                    </DateContainer>
                  ) : null}
                </ContainerMenu>
              ) : null,
            )
          : null}
      </Container>
    </>
  );
}
