import { menusListDefault } from "../../../../utils/menus";
import {
  CONTAINER_PROFILE,
  SELECT_CUSTOMER_PROFILE,
  SET_IS_ADMIN,
  SET_MENU_LIST,
  SET_PROFILE_NAME,
} from "../actions/actios.types";
import { IProfileAction, IProfileInicial } from "../interfaces";

const INITIAL_STATE: IProfileInicial | any = {
  userCustomerProfile: null,
  isAdmin: false,
  profileName: "",
  containerName: "riograndedosul",
  menus: menusListDefault,
  userCustomerProfileId: null,
};

const profile = (state = INITIAL_STATE, action: IProfileAction | any) => {
  switch (action.type) {
    case SELECT_CUSTOMER_PROFILE:
      return {
        ...state,
        userCustomerProfile: action.userCustomerProfile,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
      };
    case CONTAINER_PROFILE:
      return {
        ...state,
        containerName: action.containerName,
      };
    case SET_PROFILE_NAME:
      return {
        ...state,
        profileName: action.profileName,
      };
    case SET_MENU_LIST:
      return {
        ...state,
        menus: action.menus,
      };
    default:
      return state;
  }
};

export default profile;
