import api from "../api";

export const CustomerCertificateService = {
  getAll: async (customerId: number) => {
    try {
      const { data } = await api.get<any>(
        `/CustomerCertificate/GetByCustomerId?customerId=${customerId}`,
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch {
      return {
        status: false,
        data: null,
        message: "Houve um erro ao consultar os registros.",
      };
    }
  },

  post: async (authToken: string | null, customerCertificate: any) => {
    try {
      const body = {
        ...customerCertificate,
      };
      const { data } = await api.post<any>(`/CustomerCertificate/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao cadastrar o certificado.",
      };
    }
  },

  importCertificate: async (textCrypted: string) => {
    try {
      const body = {
        textCrypted: textCrypted,
      };
      const { data } = await api.post<any>(`/CustomerCertificate/Import`, body);

      if (data.errors.length > 0 || data.ok === false) {
        return {
          status: false,
          message: "Certificado inválido.",
        };
      }

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      return {
        status: false,
        message: "Houve um erro ao atualizar o certificado.",
      };
    }
  },
  /**
   * O customer certificate tem o dado de tempo para demonstracao
   */
  getCustomerCertificate: async (customerId: number) => {
    if (!customerId) {
      return;
    }
    try {
      const { data } = await api.get<any>(
        `/CustomerCertificate/GetByCustomerId`,
        {
          params: {
            customerId: customerId,
          },
        },
      );

      return data;
    } catch (err) {
      return {
        status: false,
        message: "Houve um erro ao recuperar o certificado.",
      };
    }
  },
};
