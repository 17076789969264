import { IHearingIndex } from "./interface";
import api from "../../api";

export const markingService = {
  delete: async (id: number) => {
    try {
      await api.delete(`/HearingIndex/Delete?id=${id}`);
    } catch (error) {
      throw new Error("não foi possivel deletar essa marcação");
    }
  },
  update: async (item: IHearingIndex) => {
    try {
      await api.put(`/HearingIndex/Update`, item);
    } catch (error) {
      throw new Error("não foi possivel atualizar essa marcação");
    }
  },
  patch: async (id: string, path: string, value: string) => {
    try {
      const replaceBody = [
        {
          op: "replace",
          value: value,
          path: path,
        },
      ];

      const { data } = await api.patch<any>(
        `/HearingIndex/Update?id=${id}`,
        replaceBody,
      );

      return {
        success: true,
        body: data,
      };
    } catch (error) {
      return {
        success: false,
        body: {},
      };
    }
  },
};
